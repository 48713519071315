import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { graphql, compose, gql } from 'react-apollo';
// Style
import cx from 'classnames';
import DocumentManagementListing from './DocumentManagementListingQuery.graphql';
// import showAllListingDocumentQuery from './showAllListingDocumentQuery.graphql';
import CommonTable from '../../CommonTable/CommonTable';
import FileList from '../DocumentVerification/FileList';
import CustomPagination from '../../CustomPagination/CustomPagination';
// Send Email
import { sendEmail } from '../../../core/email/sendEmail';
// Translation
import messages from '../../../locale/messages';
import showToaster from '../../../helpers/toasterMessages/showToaster';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './DocumentVerificationListing.css';

const showAllListingDocumentListing = gql`
  query showAllListingDocument($currentPage: Int, $searchList: String) {
    showAllListingDocument(currentPage: $currentPage, searchList: $searchList) {
      status
      count
      results {
        id
        userId
        title
        description
        documentsVerified
        documents {
          id
          fileName
          fileType
          documentStatus
        }
        user {
          email
        }
        profile {
          firstName
        }
      }
    }
  }
`;

class DocumentVerificationListing extends React.Component {
  static propTypes = {
    data: PropTypes.array,
    title: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.state = {
      currentPage: 1,
      searchList: '',
      typing: false,
      typingTimeout: 0,
    };
  }

  async handleUpdate(id, status, item) {
    const { mutate, currentPage } = this.props;
    const { data } = await mutate({
      variables: {
        ids: item.documents.map((doc) => doc.id),
        listId: id,
        documentsVerified: status === 'approved',
      },
      refetchQueries: [
        { query: showAllListingDocumentListing, variables: { currentPage } },
      ],
    });

    if (data?.DocumentManagementListing?.status === 'success') {
      let msg = 'Documents have been ';
      msg += status == 'approved' ? 'Approved.' : 'Rejected.';
      let content = {
        name: item.profile.firstName,
        listId: item.id,
        verificationStatus: status == 'approved' ? 'approved' : 'rejected',
      };
      sendEmail(item.user.email, 'documentVerificationListing', content);
      showToaster({
        messageId: 'commonSuccess',
        toasterType: 'success',
        requestMessage: msg,
      });
    } else {
      showToaster({
        messageId: 'documentManagementError',
        toasterType: 'error',
        requestMessage:
          data.DocumentManagementListing &&
          data.DocumentManagementListing.errorMessage,
      });
    }
  }

  paginationData = (currentPage) => {
    const {
      showAllListingDocumentQuery: { refetch },
      setStateVariable,
    } = this.props;
    let variables = { currentPage };
    setStateVariable(variables);
    refetch(variables);
  };

  handleClick = (searchList) => {
    const {
      showAllListingDocumentQuery: { refetch },
      setStateVariable,
    } = this.props;
    let variables = {
      currentPage: 1,
      searchList: searchList,
    };
    this.setState(variables);
    setStateVariable(variables);
    refetch(variables);
  };

  handleSearchChange = (e) => {
    const self = this;
    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout);
    }
    self.setState({
      searchList: e.target.value,
      typing: false,
      typingTimeout: setTimeout(function () {
        self.handleClick(self.state.searchList);
      }, 450),
    });
  };

  thead = () => {
    const { formatMessage } = this.props.intl;
    return [
      { data: formatMessage(messages.sNotoId) },
      { data: formatMessage(messages.listingTitle) },
      { data: formatMessage(messages.requestedFiles) },
      { data: formatMessage(messages.actionLabel) },
    ];
  };

  tbody = () => {
    const {
      showAllListingDocument: { showAllListingDocument },
      currentPage,
    } = this.props;
    const { formatMessage } = this.props.intl;

    if (
      showAllListingDocument &&
      showAllListingDocument.results &&
      showAllListingDocument.results.length > 0
    )
      return showAllListingDocument.results?.map((value, key) => {
        let selectValue;
        if (value.documents.some((doc) => doc.documentStatus === 'pending')) {
          selectValue = 'pending';
        } else {
          selectValue = value.documentsVerified ? 'approved' : 'rejected';
        }
        return {
          id: value?.id,
          data: [
            { data: value?.id },
            {
              data: value?.title,
            },
            { data: <FileList key={'f' + key} data={value.documents} /> },
            {
              data: (
                <select
                  value={selectValue}
                  onChange={(e) =>
                    this.handleUpdate(
                      value.id,
                      e && e.target && e.target.value,
                      value
                    )
                  }
                >
                  <option value={'pending'}>
                    {formatMessage(messages.messageStatus5)}
                  </option>
                  <option value={'approved'}>
                    {formatMessage(messages.approve)}
                  </option>
                  <option value={'rejected'}>
                    {formatMessage(messages.documentReject)}
                  </option>
                </select>
              ),
            },
          ],
        };
      });
  };

  render() {
    const { formatMessage } = this.props.intl;
    const {
      showAllListingDocument: { showAllListingDocument },
      searchList,
      currentPage,
    } = this.props;

    return (
      <div className={cx(s.pagecontentWrapper, 'pagecontentWrapperRTL')}>
        <CommonTable
          thead={this.thead}
          tbody={this.tbody}
          title={formatMessage(messages.documentVerificationManagementListing)}
          isSearch
          onSearch={this.handleSearchChange}
        />
        {showAllListingDocument &&
          showAllListingDocument.results &&
          showAllListingDocument.results.length > 0 && (
            <div>
              <CustomPagination
                total={showAllListingDocument.count}
                currentPage={currentPage}
                defaultCurrent={1}
                defaultPageSize={10}
                change={this.paginationData}
                paginationLabel={formatMessage(messages.listingsLabel)}
              />
            </div>
          )}
      </div>
    );
  }
}

export default compose(
  injectIntl,
  withStyles(s),
  graphql(DocumentManagementListing, {
    options: { fetchPolicy: 'network-only' },
  }),
  graphql(showAllListingDocumentListing, {
    name: 'showAllListingDocument',
    options: (props) => ({
      fetchPolicy: 'network-only',
      variables: {
        currentPage: props.currentPage,
        searchList: props.searchList,
      },
    }),
  })
)(DocumentVerificationListing);
