import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import DropzoneComponent from 'react-dropzone-component';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from '!isomorphic-style-loader!css-loader!./filepicker.css';

import DocumentList from '../DocumentList';

import showToaster from '../../helpers/toasterMessages/showToaster';

import PictureImage from '/public/SiteIcons/photoUpload.svg';
import { createVerificationDocuments } from '../../actions/manageVerificationDocuments';
import { getVerificationDocuments } from '../../actions/getVerificationDocuments';

class DocumentUpload extends Component {
  static defaultProps = {
    documentType: 'user',
    listId: null,
    userId: null,
  };

  static propTypes = {
    documentType: PropTypes.string,
    listId: PropTypes.number,
    userId: PropTypes.string,
    createVerificationDocuments: PropTypes.func.isRequired,
    getVerificationDocuments: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.dropzone = null;
  }

  componentDidMount() {
    const { getVerificationDocuments, listId, userId } = this.props;
    const isBrowser = typeof window !== 'undefined';
    const isDocument = typeof document !== undefined;
    if (isBrowser && isDocument) {
      document.querySelector('.dz-hidden-input').style.visibility = 'visible';
      document.querySelector('.dz-hidden-input').style.opacity = '0';
      document.querySelector('.dz-hidden-input').style.height = '100%';
      document.querySelector('.dz-hidden-input').style.width = '100%';
      document.querySelector('.dz-hidden-input').style.cursor = 'pointer';
    }
  }

  complete = async (file) => {
    const { createVerificationDocuments, listId, userId } = this.props;
    let fileName, fileType;

    if (file && file.xhr) {
      const { files } = JSON.parse(file.xhr.response);
      fileName = files[0].filename;
      fileType = files[0].mimetype;

      // Dispatch the action to upload the document
      const success = await createVerificationDocuments({
        fileName,
        fileType,
        listId,
        userId,
      });

      if (success) {
        showToaster({ messageId: 'uploadDocument', toasterType: 'success' });
      } else {
        showToaster({
          messageId: 'uploadDocumentError',
          toasterType: 'error',
        });
      }
    }

    this.dropzone.removeFile(file);
  };

  addedfile = async (file) => {
    const { maxUploadSize } = this.props;

    let filetypes = ['image/jpeg', 'image/png', 'image/jpg', 'application/pdf'];
    if (file?.size > 1024 * 1024 * parseInt(maxUploadSize)) {
      this.dropzone.removeFile(file);
      showToaster({ messageId: 'maximumUploadSize', toasterType: 'error' });
      return;
    }

    if (!filetypes.includes(file.type)) {
      showToaster({ messageId: 'invalidImageFile', toasterType: 'error' });
      this.dropzone.removeFile(file);
      return;
    }
  };

  render() {
    const { placeholder, listId, documentType, userId } = this.props;
    const djsConfig = {
      dictDefaultMessage: '',
      addRemoveLinks: false,
      maxFilesize: 10,
      maxFiles: 10,
      acceptedFiles: 'image/*,application/pdf',
      hiddenInputContainer: '.dzInputContainer',
    };
    const componentConfig = {
      iconFiletypes: ['.jpg', '.png', '.pdf'],
      postUrl: documentType === 'user' ? '/documents' : '/listingDocuments',
    };
    const eventHandlers = {
      init: (dz) => (this.dropzone = dz),
      complete: this.complete,
      addedfile: this.addedfile,
    };

    return (
      <div className={cx('listPhotoContainer')}>
        <div className={cx('dzInputContainer')}>
          <DropzoneComponent
            config={componentConfig}
            eventHandlers={eventHandlers}
            djsConfig={djsConfig}
          >
            <img
              src={PictureImage}
              className={'photoUploadImg'}
              alt="PictureImage"
            />
            <span className={cx('documentPlaceholder')}>{placeholder}</span>
          </DropzoneComponent>
        </div>
        <DocumentList
          documentType={documentType}
          listId={listId}
          userId={userId}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  maxUploadSize: state?.siteSettings?.data?.maxUploadSize,
});

const mapDispatchToProps = {
  createVerificationDocuments,
  getVerificationDocuments,
};

export default compose(
  withStyles(s),
  connect(mapStateToProps, mapDispatchToProps)
)(DocumentUpload);
