import {
  CREATE_VERIFICATION_DOCUMENTS_START,
  CREATE_VERIFICATION_DOCUMENTS_SUCCESS,
  CREATE_VERIFICATION_DOCUMENTS_ERROR,
} from '../constants';
import { uploadDocument } from '../lib/graphql';
import { getVerificationDocuments } from './getVerificationDocuments';

export const createVerificationDocuments = ({
  listId = null,
  userId = null,
  fileName,
  fileType,
}) => {
  return async (dispatch, getState, { client }) => {
    try {
      dispatch({
        type: CREATE_VERIFICATION_DOCUMENTS_START,
      });

      // Perform the mutation to upload the document
      const { data } = await client.mutate({
        mutation: uploadDocument,
        variables: { fileName, fileType, listId },
      });

      // Check if the upload was successful
      if (data?.uploadDocument?.status === 'success') {
        dispatch({
          type: CREATE_VERIFICATION_DOCUMENTS_SUCCESS,
          documentCount: data?.uploadDocument?.documentCount,
          documents: data?.uploadDocument?.documents,
        });
        dispatch(getVerificationDocuments({ listId, userId }));
      } else {
        dispatch({
          type: CREATE_VERIFICATION_DOCUMENTS_ERROR,
        });
      }
    } catch (error) {
      dispatch({
        type: CREATE_VERIFICATION_DOCUMENTS_ERROR,
        payload: { error },
      });
      return false;
    }
    return true;
  };
};
