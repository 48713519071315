exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".VerifyPhoneNumberForm-spaceRightBtn-3GbXL {\n    margin-right: 12px;\n}\n\n.VerifyPhoneNumberForm-otpField-1qtUK {\n    width: 100%;    \n}\n\n.VerifyPhoneNumberForm-cancelBtn-1_CLg {\n    display: inline-block;\n}\n\n.VerifyPhoneNumberForm-enterOtpSec-27nOy{    \n    -webkit-box-align: inherit;    \n        -ms-flex-align: inherit;    \n            align-items: inherit;\n}\n\n.VerifyPhoneNumberForm-otpFieldSpace-3KUqz{\n    margin-right: 20px;\n}\n\n.VerifyPhoneNumberForm-flexWrap-1TXaB{\n    -ms-flex-wrap: wrap;\n        flex-wrap: wrap;\n}\n\n.VerifyPhoneNumberForm-displayFlex-2OPHc{\n    display: -webkit-box;\n    display: -ms-flexbox;\n    display: flex;\n    display: -webkit-flex; \n}\n\n@media screen and (max-width:767px) {\n    .VerifyPhoneNumberForm-enterOtpSec-27nOy {\n        -ms-flex-wrap: wrap;\n            flex-wrap: wrap;\n    }\n\n    .VerifyPhoneNumberForm-otpField-1qtUK {        \n        margin-bottom: 12px;\n    }\n    .VerifyPhoneNumberForm-btnSec-2pEEV{\n        -webkit-box-pack: end;\n            -ms-flex-pack: end;\n                justify-content: flex-end;\n        width: 100%;\n    }\n    .VerifyPhoneNumberForm-displayFlex-2OPHc{\n        -ms-flex-wrap: wrap;\n            flex-wrap: wrap;\n    }\n}\n\n@media screen and (min-width:1200px) {\n    .VerifyPhoneNumberForm-otpField-1qtUK{\n        width: 310px;\n    }\n}", ""]);

// exports
exports.locals = {
	"spaceRightBtn": "VerifyPhoneNumberForm-spaceRightBtn-3GbXL",
	"otpField": "VerifyPhoneNumberForm-otpField-1qtUK",
	"cancelBtn": "VerifyPhoneNumberForm-cancelBtn-1_CLg",
	"enterOtpSec": "VerifyPhoneNumberForm-enterOtpSec-27nOy",
	"otpFieldSpace": "VerifyPhoneNumberForm-otpFieldSpace-3KUqz",
	"flexWrap": "VerifyPhoneNumberForm-flexWrap-1TXaB",
	"displayFlex": "VerifyPhoneNumberForm-displayFlex-2OPHc",
	"btnSec": "VerifyPhoneNumberForm-btnSec-2pEEV"
};