exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Reviews-top-if1Ww::before {\n    top: 11px;\n}", ""]);

// exports
exports.locals = {
	"top": "Reviews-top-if1Ww"
};