import React, { Component } from 'react';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
// Redux actions
import { getVerificationDocuments } from '../../actions/getVerificationDocuments';
import { removeVerificationDocuments } from '../../actions/removeVerificationDocuments';
import showToaster from '../../helpers/toasterMessages/showToaster';
// pdf image
import pdfIcon from './pdf_image.png';
import closeIcon from '/public/SiteIcons/documentRemove.svg';
import s from './DocumentList.css';
import { connect } from 'react-redux';
import { compose } from 'react-apollo';
import { listingDocumentDir, userDocumentDir } from '../../config';

class DocumentList extends Component {
  static defaultProps = {
    documentType: 'user', // Default to 'user' documents
    listId: null,
    userId: null,
    ShowDocumentList: [],
  };

  static propTypes = {
    documentType: PropTypes.string,
    listId: PropTypes.number,
    userId: PropTypes.string,
    getVerificationDocuments: PropTypes.func.isRequired,
    removeVerificationDocuments: PropTypes.func.isRequired,
    ShowDocumentList: PropTypes.array,
  };

  componentDidMount() {
    const { getVerificationDocuments, userId, listId } = this.props;
  }

  handleClick = async (id, fileName) => {
    const { removeVerificationDocuments, userId, listId } = this.props;

    // Remove document using Redux action
    const success = await removeVerificationDocuments({
      listId,
      userId,
      documentId: id,
      fileName,
    });

    if (success) {
      showToaster({ messageId: 'documentRemoved', toasterType: 'success' });
    } else {
      showToaster({ messageId: 'documentRemoveError', toasterType: 'error' });
    }
  };

  render() {
    const { documentType, ShowDocumentList } = this.props;
    let path =
      documentType === 'listing'
        ? `${listingDocumentDir}`
        : `${userDocumentDir}`;

    return (
      <div className={cx('row')}>
        {ShowDocumentList &&
          ShowDocumentList.map((item, key) => {
            let icon =
              item.fileType === 'application/pdf'
                ? pdfIcon
                : path + item.fileName;
            return (
              <div
                key={key}
                className={cx(
                  'col-lg-4 col-md-4 col-sm-6 col-xs-12 text-center'
                )}
              >
                <div className={s.relative}>
                  <a href={path + item.fileName} target="_blank">
                    <div className={s.listPhotoCover}>
                      <div className={s.listPhotoMedia}>
                        <img className={s.imgResponsive} src={icon} />
                      </div>
                    </div>
                  </a>
                  <a
                    href="javascript:void(0);"
                    onClick={() => this.handleClick(item.id, item.fileName)}
                    className={cx(s.position, 'positionDocumentRTL')}
                  >
                    <img src={closeIcon} />
                  </a>
                </div>
              </div>
            );
          })}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ShowDocumentList: state.documentVerification.documents, // Assuming you store documents in state.verificationDocuments.documents
  };
};

const mapDispatchToProps = {
  getVerificationDocuments,
  removeVerificationDocuments,
};

export default injectIntl(
  compose(
    withStyles(s),
    connect(mapStateToProps, mapDispatchToProps)
  )(DocumentList)
);
