exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ListingDetails-showHideBtn-16wN9,\n.ListingDetails-showHideBtn-16wN9.ListingDetails-focus-2Rzt5,\n.ListingDetails-showHideBtn-16wN9:active,\n.ListingDetails-showHideBtn-16wN9:hover {\n  padding: 0px !important;\n  font-weight: 500;\n  word-wrap: break-word;\n  line-height: 18px;\n  letter-spacing: normal;\n  font-size: 18px;\n  border: 0px !important;\n  text-decoration: none !important;\n  border-bottom: 1px solid var(--common-text-linkcolor) !important;\n  border-bottom: 1px solid var(--common-text-linkcolor) !important;\n  color: var(--common-text-linkcolor) !important;\n  color: var(--common-text-linkcolor) !important;\n}\n\n.ListingDetails-streeingImage-2NIg9 {\n  width: 100%;\n  max-width: 18px;\n  position: relative;\n  top: 4px;\n}\n\n.ListingDetails-displayFlex-3L2IH {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: start;\n      -ms-flex-align: start;\n          align-items: flex-start;\n}\n\n.ListingDetails-sectionLink-1mCj_ {\n  border: 1px solid var(--btn-primary-bg);\n  border: 1px solid var(--btn-primary-bg);\n  padding: 10px;\n  border-radius: 60px;\n}\n\n.ListingDetails-minMaxSection-119Et {\n  display: grid;\n  grid-template-columns: 50% 50%;\n}\n\n.ListingDetails-listItemSection-1eemM {\n  display: inline-block;\n  width: 50%;\n}\n\n@media screen and (max-width: 767px) {\n  .ListingDetails-listItemSection-1eemM {\n    width: 100%;\n  }\n\n  .ListingDetails-minMaxSection-119Et {\n    grid-template-columns: 100%;\n  }\n}", ""]);

// exports
exports.locals = {
	"showHideBtn": "ListingDetails-showHideBtn-16wN9",
	"focus": "ListingDetails-focus-2Rzt5",
	"streeingImage": "ListingDetails-streeingImage-2NIg9",
	"displayFlex": "ListingDetails-displayFlex-3L2IH",
	"sectionLink": "ListingDetails-sectionLink-1mCj_",
	"minMaxSection": "ListingDetails-minMaxSection-119Et",
	"listItemSection": "ListingDetails-listItemSection-1eemM"
};