import React from 'react';

const MinMaxInput = ({ input, meta, min, max, ...props }) => {
  const handleChange = (event) => {
    let value = event.target.value;

    // Remove leading zeros and prevent negative numbers
    if (value !== '') {
      value = value.replace(/^0+/, ''); // Remove leading zeros
      value = value.replace(/^-/, ''); // Prevent negative numbers
    }

    // If the value is empty, set it to an empty string (allows for clearing the input)
    if (value === '') {
      input.onChange('');
      return;
    }

    // Convert the value to a number for comparison
    value = Number(value);

    // Ensure the value is within the allowed range and not more than 2 digits
    if (value > max) {
      value = max;
    }

    if (value.toString().length > 2) {
      value = value.toString().slice(0, 2);
    }

    // Update the input value
    input.onChange(value);
  };

  const handleBlur = (event) => {
    let value = event.target.value;

    // If the field is left empty, set it to the minimum value
    if (value === '') {
      input.onChange(min);
    }
  };

  return (
    <input
      {...input}
      {...props}
      type="number"
      value={input.value}
      onChange={handleChange}
      onBlur={handleBlur}
      min={min}
      max={max}
      maxLength="2"
    />
  );
};

export default MinMaxInput;
