import {
  GET_VERIFICATION_DOCUMENTS_START,
  GET_VERIFICATION_DOCUMENTS_SUCCESS,
  GET_VERIFICATION_DOCUMENTS_ERROR,
} from '../constants';
import { showVerificationDocuments } from '../lib/graphql';

export const getVerificationDocuments = ({ listId, userId }) => {
  return async (dispatch, getState, { client }) => {
    try {
      dispatch({
        type: GET_VERIFICATION_DOCUMENTS_START,
      });

      // Determine the query variables based on what is provided
      const variables = { userId };
      if (listId) {
        variables.listId = listId;
      }

      const { data } = await client.query({
        query: showVerificationDocuments,
        variables,
        fetchPolicy: 'network-only',
      });

      if (data?.ShowDocumentList) {
        dispatch({
          type: GET_VERIFICATION_DOCUMENTS_SUCCESS,
          documents: data.ShowDocumentList,
          documentCount: data.ShowDocumentList.length,
        });
      } else {
        dispatch({
          type: GET_VERIFICATION_DOCUMENTS_ERROR,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_VERIFICATION_DOCUMENTS_ERROR,
        payload: { error },
      });
      return false;
    }
    return true;
  };
};
