import React from 'react';
import PropTypes from 'prop-types';
import { graphql, compose, gql } from 'react-apollo';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import Loader from '../../../components/Loader/Loader';

// import showAllDocumentListingQuery from '../../../data/queries/siteadmin/DocumentListing/showAllListingDocument';

import s from './DocumentListing.css';
import DocumentVerificationListing from '../../../components/siteadmin/DocumentVerificationListing/DocumentVerificationListing';

const showAllListingDocument = gql`
  query showAllListingDocument($currentPage: Int, $searchList: String) {
    showAllListingDocument(currentPage: $currentPage, searchList: $searchList) {
      status
      count
      results {
        id
        userId
        title
        description
        documentsVerified
        documents {
          id
          fileName
          fileType
          documentStatus
        }
        user {
          email
        }
        profile {
          firstName
        }
      }
    }
  }
`;

class DocumentListing extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    data: PropTypes.shape({
      loading: PropTypes.bool,
      getAllDocument: PropTypes.array,
    }),
  };

  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      searchList: '',
    };
    this.setStateVariable = this.setStateVariable.bind(this);
  }

  static defaultProps = {
    data: {
      loading: true,
    },
  };

  setStateVariable(variables) {
    this.setState(variables);
  }

  render() {
    const {
      data: { loading, showAllListingDocument, refetch },
      title,
    } = this.props;
    const { searchList, currentPage } = this.state;

    if (loading) {
      return <Loader type={'text'} />;
    } else {
      return (
        <DocumentVerificationListing
          showAllDocument={showAllListingDocument}
          searchList={searchList}
          currentPage={currentPage}
          refetch={refetch}
          setStateVariable={this.setStateVariable}
          title={title}
        />
      );
    }
  }
}

export default compose(
  withStyles(s),
  graphql(showAllListingDocument, {
    options: (props) => ({
      fetchPolicy: 'network-only',
    }),
  })
)(DocumentListing);
