var escape = require("../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Trips-formControlInput-MezoI {\n    width: 340px;\n    max-width: 100%;\n    height: 48px;\n    border: 1px solid var(--search-input-border);\n    border: 1px solid var(--search-input-border);\n    border-radius: 6px;\n}\n\n.Trips-locationBgIcon-2VrnW {\n    background-repeat: no-repeat;\n    background-position: 18px 50%;\n    padding-left: 40px;\n    padding-right: 18px;\n    background-size: 13px;\n    background-image: url(" + escape(require("../../../public/SiteIcons/searchIcon.svg")) + ");\n}", ""]);

// exports
exports.locals = {
	"formControlInput": "Trips-formControlInput-MezoI",
	"locationBgIcon": "Trips-locationBgIcon-2VrnW"
};