exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".WishListGroupModal-marginLeft-1OB9q{\n    margin-left: 12px;\n}\n.WishListGroupModal-paddingTop-DuXGD{\n    padding-top: 50px;\n}\n@media screen and (max-width: 767px) {\n.WishListGroupModal-mobileBtn-1hyCP{\n    width: 100%;\n}\n.WishListGroupModal-marginLeft-1OB9q{\n    margin-left: 0;\n    margin-top: 12px;\n}\n}", ""]);

// exports
exports.locals = {
	"marginLeft": "WishListGroupModal-marginLeft-1OB9q",
	"paddingTop": "WishListGroupModal-paddingTop-DuXGD",
	"mobileBtn": "WishListGroupModal-mobileBtn-1hyCP"
};