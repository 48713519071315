exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ChangePasswordForm-formControlpassWord-3di_L{\n    padding: 10px 45px 10px 19px;\n}", ""]);

// exports
exports.locals = {
	"formControlpassWord": "ChangePasswordForm-formControlpassWord-3di_L"
};