exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".PopularLocationSlider-positionRelative-3nqJg {\n\tposition: relative;\n}\n\n@media (max-width: 1199px) and (min-width: 1024px) {\n\t.PopularLocationSlider-paddingRight-2pQf8 {\n\t\tpadding-right: 15px;\n\t}\n}\n\n@media (max-width: 1023px) and (min-width: 768px) {\n\t.PopularLocationSlider-paddingRight-2pQf8 {\n\t\tpadding-right: 15px;\n\t}\n}\n\n.PopularLocationSlider-paddingNone-1zWsl {\n\tpadding: 0 !important;\n}\n\n@media(max-width:1200px) {\n\t.PopularLocationSlider-displayNone-2okt8 {\n\t\tdisplay: none !important;\n\t}\n}\n\n@media(max-width:320px) {\n\t.PopularLocationSlider-displayFlex-OSkL4 {\n\t\tdisplay: -webkit-box;\n\t\tdisplay: -ms-flexbox;\n\t\tdisplay: flex;\n\t\t-webkit-box-pack: center;\n\t\t    -ms-flex-pack: center;\n\t\t        justify-content: center;\n\t}\n}", ""]);

// exports
exports.locals = {
	"positionRelative": "PopularLocationSlider-positionRelative-3nqJg",
	"paddingRight": "PopularLocationSlider-paddingRight-2pQf8",
	"paddingNone": "PopularLocationSlider-paddingNone-1zWsl",
	"displayNone": "PopularLocationSlider-displayNone-2okt8",
	"displayFlex": "PopularLocationSlider-displayFlex-OSkL4"
};