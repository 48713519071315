exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".EditWishListGroup-editBtnMargin-g7yJL {\n    margin: 0px 12px;\n}\n\n.EditWishListGroup-closeWidth-2KpTN {\n    width: 100%;\n    max-width: 12px;\n}\n\n.EditWishListGroup-noWishList-cb5x-{\n    text-align: center;\n}\n\n.EditWishListGroup-btnlarge-36Gq8{\n    display: inline-block;\n    padding: 9px 19px 11px;\n}\n\n.EditWishListGroup-iconWidth-24wVL{\n    max-width: 14px;\n}\n\n.EditWishListGroup-iconDeleteWidth-3Ql7D{\n    max-width: 12px;\n}\n\n.EditWishListGroup-listGrid-3HAzn{\n    display: grid;\n    grid-template-columns: 330px 330px 330px;\n    gap: 30px;\n}\n\n@media (min-width: 768px) and (max-width: 1024px) {\n    .EditWishListGroup-listGrid-3HAzn {\n        grid-template-columns: repeat(auto-fill, 226px);\n    }\n}\n\n@media screen and (max-width: 767px) {\n    .EditWishListGroup-flexWrapMb-2FmSV{\n        -ms-flex-wrap: wrap;\n            flex-wrap: wrap;\n        -webkit-box-pack: end;\n            -ms-flex-pack: end;\n                justify-content: end;\n        gap: 12px;\n    }\n    .EditWishListGroup-listGrid-3HAzn {\n        grid-template-columns: 100%;\n    }\n}", ""]);

// exports
exports.locals = {
	"editBtnMargin": "EditWishListGroup-editBtnMargin-g7yJL",
	"closeWidth": "EditWishListGroup-closeWidth-2KpTN",
	"noWishList": "EditWishListGroup-noWishList-cb5x-",
	"btnlarge": "EditWishListGroup-btnlarge-36Gq8",
	"iconWidth": "EditWishListGroup-iconWidth-24wVL",
	"iconDeleteWidth": "EditWishListGroup-iconDeleteWidth-3Ql7D",
	"listGrid": "EditWishListGroup-listGrid-3HAzn",
	"flexWrapMb": "EditWishListGroup-flexWrapMb-2FmSV"
};