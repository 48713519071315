import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
// Style
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import { FormGroup } from 'react-bootstrap';
import s from './ListPlaceStep1.css';
import cs from '../commonStyle.css';
// Internal Component
import DocumentUpload from '../../components/DocumentUpload';
import FooterButton from './FooterButton';
import SidePanel from './SidePanel';
import updateStep2 from './updateStep2';
// Locale
import messages from '../../locale/messages';
class CarDocuments extends Component {
  static propTypes = {
    previousPage: PropTypes.any,
    listId: PropTypes.number.isRequired,
    cardocumentCount: PropTypes.number,
    account: PropTypes.shape({
      userId: PropTypes.string,
    }),
  };

  constructor(props) {
    super(props);
    this.state = {
      isAvailable: false,
    };
  }

  UNSAFE_componentWillMount() {
    const { cardocumentCount } = this.props;
    const { valid } = this.props;
    if (cardocumentCount > 0) {
      this.setState({ isAvailable: true });
    }
    if (valid) {
      this.setState({ isDisabled: false });
    } else {
      this.setState({ isDisabled: true });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { cardocumentCount } = nextProps;
    const { valid } = nextProps;
    if (cardocumentCount > 0) {
      this.setState({ isAvailable: true });
    } else {
      this.setState({ isAvailable: false });
    }
    if (valid) {
      this.setState({ isDisabled: false });
    } else {
      this.setState({ isDisabled: true });
    }
  }

  render() {
    const {
      previousPage,
      listId,
      formPage,
      nextPage,
      step,
      account: { userId },
    } = this.props;
    const { formatMessage } = this.props.intl;
    const { isDisabled } = this.state;

    return (
      <div className={cx(s.stepGrid, 'stepGridRTL')}>
        <SidePanel
          title={formatMessage(messages.step2Heading)}
          landingContent={formatMessage(messages.step2DocumentsTitle)}
        />
        <form>
          <div className={s.landingMainContent}>
            <h3 className={cx(cs.commonContentText, cs.spaceBottom3)}>
              <FormattedMessage {...messages.carDocuments} />
            </h3>
            <FormGroup className={cx(s.formGroup, 'stepPhotoUpload')}>
              <DocumentUpload
                placeholder={formatMessage(messages.documentUploadPlaceholder)}
                listId={listId}
                userId={userId}
                documentType="listing"
              />
            </FormGroup>
          </div>
          <FooterButton
            previousPage={previousPage}
            previousPagePath={'photos'}
            isDisabled={isDisabled}
            formPage={formPage}
            step={step}
            nextPage={nextPage}
            nextPagePath={'description'}
          />
        </form>
      </div>
    );
  }
}

CarDocuments = reduxForm({
  form: 'ListPlaceStep2', // a unique name for this form
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  onSubmit: updateStep2,
})(CarDocuments);

const mapState = (state) => ({
  listingFields: state.listingFields.data,
  listingSteps: state.location.listingSteps,
  cardocumentCount: state.location.cardocumentCount,
  account: state?.account?.data,
});
const mapDispatch = {};

export default injectIntl(
  withStyles(s)(connect(mapState, mapDispatch)(CarDocuments))
);
