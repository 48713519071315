import messages from '../../locale/messages';

const validateStep2 = (values) => {
  const errors = {};

  if (!values.title) {
    errors.title = messages.required;
  } else if (values.title && values.title.trim() === '') {
    errors.title = messages.required;
  }

  if (!values.description) {
    errors.description = messages.required;
  } else if (values.description && values.description.trim() === '') {
    errors.description = messages.required;
  }

  if (!values.vinNumber) {
    errors.vinNumber = messages.required;
  } else if (values.vinNumber && values.vinNumber.trim() === '') {
    errors.vinNumber = messages.required;
  } else if (values.vinNumber.length !== 17) {
    errors.vinNumber = messages.invalidVinNumber;
  }

  if (!values.licensePlate) {
    errors.licensePlate = messages.required;
  } else if (values.licensePlate && values.licensePlate.trim() === '') {
    errors.licensePlate = messages.required;
  } else if (values.licensePlate.length > 10) {
    errors.licensePlate = messages.invalidLicensePlate;
  }

  return errors;
};

export default validateStep2;
