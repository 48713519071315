exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".HostDetail-showHideBtn-kZd2j,\n.HostDetail-showHideBtn-kZd2j.HostDetail-focus-3nhok,\n.HostDetail-showHideBtn-kZd2j:active,\n.HostDetail-showHideBtn-kZd2j:hover {\n  padding: 0px !important;\n  font-weight: 500;\n  word-wrap: break-word;\n  line-height: 18px;\n  letter-spacing: normal;\n  font-size: 18px;\n  border: 0px !important;\n  text-decoration: none !important;\n  border-bottom: 1px solid var(--common-text-linkcolor) !important;\n  border-bottom: 1px solid var(--common-text-linkcolor) !important;\n  color: var(--common-text-linkcolor) !important;\n  color: var(--common-text-linkcolor) !important;\n}\n.HostDetail-displayFlex-l3HLN {\n    display: -webkit-box;\n    display: -ms-flexbox;\n    display: flex;\n    -webkit-box-align: start;\n        -ms-flex-align: start;\n            align-items: flex-start;\n  }", ""]);

// exports
exports.locals = {
	"showHideBtn": "HostDetail-showHideBtn-kZd2j",
	"focus": "HostDetail-focus-3nhok",
	"displayFlex": "HostDetail-displayFlex-l3HLN"
};