import {
  REMOVE_VERIFICATION_DOCUMENTS_START,
  REMOVE_VERIFICATION_DOCUMENTS_SUCCESS,
  REMOVE_VERIFICATION_DOCUMENTS_ERROR,
} from '../constants';
import { removeDocument } from '../lib/graphql';
import { getVerificationDocuments } from './getVerificationDocuments';

export const removeVerificationDocuments = ({
  listId,
  userId,
  documentId,
  fileName,
}) => {
  return async (dispatch, getState, { client }) => {
    try {
      dispatch({
        type: REMOVE_VERIFICATION_DOCUMENTS_START,
      });

      // Perform the mutation to remove the document
      const { data } = await client.mutate({
        mutation: removeDocument,
        variables: { id: documentId, listId },
      });

      if (data?.RemoveDocumentList.status === 'success') {
        // Remove the file physically
        const removeDocumentRoute =
          listId !== null ? '/deleteListingDocuments' : '/deleteDocuments'; // Define a route based on the document type
        const resp = await fetch(removeDocumentRoute, {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ fileName: fileName }),
        });

        const { status } = await resp.json();

        if (status === 'success') {
          dispatch({
            type: REMOVE_VERIFICATION_DOCUMENTS_SUCCESS,
            documentCount: data?.RemoveDocumentList.documentCount,
            documents: data.RemoveDocumentList.documents,
          });
          dispatch(getVerificationDocuments({ listId, userId }));
        }
      } else {
        dispatch({
          type: REMOVE_VERIFICATION_DOCUMENTS_ERROR,
        });
      }
    } catch (error) {
      dispatch({
        type: REMOVE_VERIFICATION_DOCUMENTS_ERROR,
        payload: { error },
      });
      return false;
    }
    return true;
  };
};
