/* eslint-disable import/prefer-default-export */

export const SET_RUNTIME_VARIABLE = 'SET_RUNTIME_VARIABLE';
export const SET_LOCALE_START = 'SET_LOCALE_START';
export const SET_LOCALE_SUCCESS = 'SET_LOCALE_SUCCESS';
export const SET_LOCALE_ERROR = 'SET_LOCALE_ERROR';
export const FETCH_CONTENT_START = 'FETCH_CONTENT_START';
export const FETCH_CONTENT_SUCCESS = 'FETCH_CONTENT_SUCCESS';
export const FETCH_CONTENT_ERROR = 'FETCH_CONTENT_ERROR';
export const LOAD_USER_DATA = 'LOAD_USER_DATA';

// Load User Account Data
export const SET_USER_DATA_START = 'SET_USER_DATA_START';
export const SET_USER_DATA_SUCCESS = 'SET_USER_DATA_SUCCESS';
export const SET_USER_DATA_ERROR = 'SET_USER_DATA_ERROR';

// Delete Blog Details
export const ADMIN_DELETE_BlOGDETAILS_START = 'ADMIN_DELETE_BlOGDETAILS_START';
export const ADMIN_DELETE_BlOGDETAILS_SUCCESS =
  'ADMIN_DELETE_BlOGDETAILS_SUCCESS';
export const ADMIN_DELETE_BlOGDETAILS_ERROR = 'ADMIN_DELETE_BlOGDETAILS_ERROR';

// Logout
export const USER_LOGOUT_START = 'USER_LOGOUT_START';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const USER_LOGOUT_ERROR = 'USER_LOGOUT_ERROR';

// Apply Settings on Front End
export const SET_SITE_SETTINGS_START = 'SET_SITE_SETTINGS_START';
export const SET_SITE_SETTINGS_SUCCESS = 'SET_SITE_SETTINGS_SUCCESS';
export const SET_SITE_SETTINGS_ERROR = 'SET_SITE_SETTINGS_ERROR';

// List your space - step #1 - Get Location Data
export const GET_LOCATION_DATA_START = 'GET_LOCATION_DATA_START';
export const GET_LOCATION_DATA_SUCCESS = 'GET_LOCATION_DATA_SUCCESS';
export const GET_LOCATION_DATA_ERROR = 'GET_LOCATION_DATA_ERROR';

// List your space - step #1 - Update Location Status
export const UPDATE_LOCATION_STATUS = 'UPDATE_LOCATION_STATUS';

// List your space - step #1 - Get Listing Data
export const GET_LISTING_DATA_START = 'GET_LISTING_DATA_START';
export const GET_LISTING_DATA_SUCCESS = 'GET_LISTING_DATA_SUCCESS';
export const GET_LISTING_DATA_ERROR = 'GET_LISTING_DATA_ERROR';

// List your space - step #2 - Get Listing Data
export const GET_LISTING_DATA_STEP2_START = 'GET_LISTING_DATA_STEP2_START';
export const GET_LISTING_DATA_STEP2_SUCCESS = 'GET_LISTING_DATA_STEP2_SUCCESS';
export const GET_LISTING_DATA_STEP2_ERROR = 'GET_LISTING_DATA_STEP2_ERROR';

// List your space - step #3 - Get Listing Data
export const GET_LISTING_DATA_STEP3_START = 'GET_LISTING_DATA_STEP3_START';
export const GET_LISTING_DATA_STEP3_SUCCESS = 'GET_LISTING_DATA_STEP3_SUCCESS';
export const GET_LISTING_DATA_STEP3_ERROR = 'GET_LISTING_DATA_STEP3_ERROR';

// List your space - step #1 - Update Map Data from Location Form
export const UPDATE_LISTING_MAP_START = 'UPDATE_LISTING_MAP_START';
export const UPDATE_LISTING_MAP_SUCCESS = 'UPDATE_LISTING_MAP_SUCCESS';
export const UPDATE_LISTING_MAP_ERROR = 'UPDATE_LISTING_MAP_ERROR';

// List your space - steps - Get Listing Steps Data
export const GET_LISTING_STEPS_DATA_START = 'GET_LISTING_STEPS_DATA_START';
export const SET_NEW_LISTING_DATA = 'SET_NEW_LISTING_DATA';
export const GET_LISTING_STEPS_DATA_SUCCESS = 'GET_LISTING_STEPS_DATA_SUCCESS';
export const GET_LISTING_STEPS_DATA_ERROR = 'GET_LISTING_STEPS_DATA_ERROR';

// List your space - Manage Listing Steps Data
export const MANAGE_LISTING_STEPS_DATA_START =
  'MANAGE_LISTING_STEPS_DATA_START';
export const MANAGE_LISTING_STEPS_DATA_SUCCESS =
  'MANAGE_LISTING_STEPS_DATA_SUCCESS';
export const MANAGE_LISTING_STEPS_DATA_ERROR =
  'MANAGE_LISTING_STEPS_DATA_ERROR';

// Toggle Menu
export const OPEN_TOGGLE_MENU = 'OPEN_TOGGLE_MENU';
export const CLOSE_TOGGLE_MENU = 'CLOSE_TOGGLE_MENU';

// Login Modal Open and Close actions
export const OPEN_LONGIN_MODAL = 'OPEN_LONGIN_MODAL';
export const CLOSE_LONGIN_MODAL = 'CLOSE_LONGIN_MODAL';

// Signup Modal Open and Close actions
export const OPEN_SIGNUP_MODAL = 'OPEN_SIGNUP_MODAL';
export const CLOSE_FORGOT_PASSWORD_MODAL = 'CLOSE_FORGOT_PASSWORD_MODAL';

// Signup Modal Open and Close actions
export const OPEN_FORGOT_PASSWORD_MODAL = 'OPEN_FORGOT_PASSWORD_MODAL';
export const CLOSE_SIGNUP_MODAL = 'CLOSE_SIGNUP_MODAL';

// List your space - Get Listing Settings
export const GET_LISTING_FIELDS_DATA_START = 'GET_LISTING_FIELDS_DATA_START';
export const GET_LISTING_FIELDS_DATA_SUCCESS =
  'GET_LISTING_FIELDS_DATA_SUCCESS';
export const GET_LISTING_FIELDS_DATA_ERROR = 'GET_LISTING_FIELDS_DATA_ERROR';

// Get Specific Listing Settings
export const GET_LISTING_SPECIFIC_FIELDS_DATA_START =
  'GET_LISTING_SPECIFIC_FIELDS_DATA_START';
export const GET_LISTING_SPECIFIC_FIELDS_DATA_SUCCESS =
  'GET_LISTING_SPECIFIC_FIELDS_DATA_SUCCESS';
export const GET_LISTING_SPECIFIC_FIELDS_DATA_ERROR =
  'GET_LISTING_SPECIFIC_FIELDS_DATA_ERROR';

// List your space - Get Listing Fields Initial Values
export const GET_LISTING_FIELDS_INTIAL_VALUES_START =
  'GET_LISTING_FIELDS_INTIAL_VALUES_START';
export const GET_LISTING_FIELDS_INTIAL_VALUES_SUCCESS =
  'GET_LISTING_FIELDS_INTIAL_VALUES_SUCCESS';
export const GET_LISTING_FIELDS_INTIAL_VALUES_ERROR =
  'GET_LISTING_FIELDS_INTIAL_VALUES_ERROR';

// List your space - Create List Photos
export const CREATE_LIST_PHOTOS_START = 'CREATE_LIST_PHOTOS_START';
export const CREATE_LIST_PHOTOS_SUCCESS = 'CREATE_LIST_PHOTOS_SUCCESS';
export const CREATE_LIST_PHOTOS_ERROR = 'CREATE_LIST_PHOTOS_ERROR';

// List your space - Show List Photos
export const SHOW_LIST_PHOTOS_START = 'SHOW_LIST_PHOTOS_START';
export const SHOW_LIST_PHOTOS_SUCCESS = 'SHOW_LIST_PHOTOS_SUCCESS';
export const SHOW_LIST_PHOTOS_ERROR = 'SHOW_LIST_PHOTOS_ERROR';

// List your space - Show List Photos
export const REMOVE_LIST_PHOTOS_START = 'REMOVE_LIST_PHOTOS_START';
export const REMOVE_LIST_PHOTOS_SUCCESS = 'REMOVE_LIST_PHOTOS_SUCCESS';
export const REMOVE_LIST_PHOTOS_ERROR = 'REMOVE_LIST_PHOTOS_ERROR';

// List your space - Show List Photos
export const CHECK_AVAILABLE_DATES_START = 'CHECK_AVAILABLE_DATES_START';
export const CHECK_AVAILABLE_DATES_SUCCESS = 'CHECK_AVAILABLE_DATES_SUCCESS';
export const CHECK_AVAILABLE_DATES_ERROR = 'CHECK_AVAILABLE_DATES_ERROR';

// Currency Rates Fetch
export const CURRENCY_RATES_FETCH_START = 'CURRENCY_RATES_FETCH_START';
export const CURRENCY_RATES_FETCH_SUCCESS = 'CURRENCY_RATES_FETCH_SUCCESS';
export const CURRENCY_RATES_FETCH_ERROR = 'CURRENCY_RATES_FETCH_ERROR';

// Store Currency Rates
export const STORE_CURRENCY_RATES_START = 'STORE_CURRENCY_RATES_START';
export const STORE_CURRENCY_RATES_SUCCESS = 'STORE_CURRENCY_RATES_SUCCESS';
export const STORE_CURRENCY_RATES_ERROR = 'STORE_CURRENCY_RATES_ERROR';

// Get Currency List from Currencies Table
export const GET_CURRENCIES_START = 'GET_CURRENCIES_START';
export const GET_CURRENCIES_SUCCESS = 'GET_CURRENCIES_SUCCESS';
export const GET_CURRENCIES_ERROR = 'GET_CURRENCIES_ERROR';

// Select Currency from Dropdown
export const CHOSE_TO_CURRENCY_START = 'CHOSE_TO_CURRENCY_START';
export const CHOSE_TO_CURRENCY_SUCCESS = 'CHOSE_TO_CURRENCY_SUCCESS';
export const CHOSE_TO_CURRENCY_ERROR = 'CHOSE_TO_CURRENCY_ERROR';

// Remove Listing
export const REMOVE_LISTING_START = 'REMOVE_LISTING_START';
export const REMOVE_LISTING_SUCCESS = 'REMOVE_LISTING_SUCCESS';
export const REMOVE_LISTING_ERROR = 'REMOVE_LISTING_ERROR';

// Manage Listing Publish Status
export const MANANGE_LISTING_PUBLISH_STATUS_START =
  'MANANGE_LISTING_PUBLISH_STATUS_START';
export const MANANGE_LISTING_PUBLISH_STATUS_SUCCESS =
  'MANANGE_LISTING_PUBLISH_STATUS_SUCCESS';
export const MANANGE_LISTING_PUBLISH_STATUS_ERROR =
  'MANANGE_LISTING_PUBLISH_STATUS_ERROR';

// View Listing - Image Slider Lightbox
export const IMAGE_LIGHTBOX_OPEN = 'IMAGE_LIGHTBOX_OPEN';
export const IMAGE_LIGHTBOX_CLOSE = 'IMAGE_LIGHTBOX_CLOSE';

// Search Results
export const LOADING_SEARCH_RESULTS = 'LOADING_SEARCH_RESULTS';
export const FETCH_SEARCH_RESULTS_START = 'FETCH_SEARCH_RESULTS_START';
export const FETCH_SEARCH_RESULTS_SUCCESS = 'FETCH_SEARCH_RESULTS_SUCCESS';
export const FETCH_SEARCH_RESULTS_ERROR = 'FETCH_SEARCH_RESULTS_ERROR';

// Search Filter Toggle
export const SEARCH_FILTER_TOGGLE_OPEN = 'SEARCH_FILTER_TOGGLE_OPEN';
export const SEARCH_FILTER_TOGGLE_CLOSE = 'SEARCH_FILTER_TOGGLE_CLOSE';

// Get Search Settings
export const GET_SEARCH_SETTINGS_START = 'GET_SEARCH_SETTINGS_START';
export const GET_SEARCH_SETTINGS_SUCCESS = 'GET_SEARCH_SETTINGS_SUCCESS';
export const GET_SEARCH_SETTINGS_ERROR = 'GET_SEARCH_SETTINGS_ERROR';

// Set Personalized Values
export const SET_PERSONALIZED_VALUES = 'SET_PERSONALIZED_VALUES';

// Search from home page
export const SEARCH_LISTING_START = 'SEARCH_LISTING_START';
export const SEARCH_LISTING_SUCCESS = 'SEARCH_LISTING_SUCCESS';
export const SEARCH_LISTING_ERROR = 'SEARCH_LISTING_ERROR';

// Search page on Mobile screen - Show Map
export const SHOW_SEARCH_RESULTS_MAP = 'SHOW_SEARCH_RESULTS_MAP';

// Search page on Mobile screen - Show Results
export const SHOW_SEARCH_RESULTS = 'SHOW_SEARCH_RESULTS';

// Search page on Mobile screen - Show Form
export const SHOW_SEARCH_FORM = 'SHOW_SEARCH_FORM';

// Trust and Verification - Disconnect from Social Media
export const UPDATE_VERIFICATION_START = 'UPDATE_VERIFICATION_START';
export const UPDATE_VERIFICATION_SUCCESS = 'UPDATE_VERIFICATION_SUCCESS';
export const UPDATE_VERIFICATION_ERROR = 'UPDATE_VERIFICATION_ERROR';

// Trust and Verification - Email Verification Confirmation
export const EMAIL_VERIFICATION_START = 'EMAIL_VERIFICATION_START';
export const EMAIL_VERIFICATION_SUCCESS = 'EMAIL_VERIFICATION_SUCCESS';
export const EMAIL_VERIFICATION_ERROR = 'EMAIL_VERIFICATION_ERROR';

// Trust and Verification - Email Verification Confirmation
export const RESEND_EMAIL_VERIFICATION_START =
  'RESEND_EMAIL_VERIFICATION_START';
export const RESEND_EMAIL_VERIFICATION_SUCCESS =
  'RESEND_EMAIL_VERIFICATION_SUCCESS';
export const RESEND_EMAIL_VERIFICATION_ERROR =
  'RESEND_EMAIL_VERIFICATION_ERROR';

// Send Forgot Password Email
export const SEND_FORGOT_PASSWORD_START = 'SEND_FORGOT_PASSWORD_START';
export const SEND_FORGOT_PASSWORD_SUCCESS = 'SEND_FORGOT_PASSWORD_SUCCESS';
export const SEND_FORGOT_PASSWORD_ERROR = 'SEND_FORGOT_PASSWORD_ERROR';

// Update Forgot Password
export const UPDATE_FORGOT_PASSWORD_START = 'UPDATE_FORGOT_PASSWORD_START';
export const UPDATE_FORGOT_PASSWORD_SUCCESS = 'UPDATE_FORGOT_PASSWORD_SUCCESS';
export const UPDATE_FORGOT_PASSWORD_ERROR = 'UPDATE_FORGOT_PASSWORD_ERROR';

// Upload Profile Picture
export const PROFILE_PICTURE_LOADER_START = 'PROFILE_PICTURE_LOADER_START';
export const UPLOAD_PROFILE_PICTURE_START = 'UPLOAD_PROFILE_PICTURE_START';
export const UPLOAD_PROFILE_PICTURE_SUCCESS = 'UPLOAD_PROFILE_PICTURE_SUCCESS';
export const UPLOAD_PROFILE_PICTURE_ERROR = 'UPLOAD_PROFILE_PICTURE_ERROR';

// Upload Profile Picture
export const REMOVE_PROFILE_PICTURE_START = 'REMOVE_PROFILE_PICTURE_START';
export const REMOVE_PROFILE_PICTURE_SUCCESS = 'REMOVE_PROFILE_PICTURE_SUCCESS';
export const REMOVE_PROFILE_PICTURE_ERROR = 'REMOVE_PROFILE_PICTURE_ERROR';

// List Views
export const RECORD_LIST_VIEWS_START = 'RECORD_LIST_VIEWS_START';
export const RECORD_LIST_VIEWS_SUCCESS = 'RECORD_LIST_VIEWS_SUCCESS';
export const RECORD_LIST_VIEWS_ERROR = 'RECORD_LIST_VIEWS_ERROR';

// Contact Host
export const CONTACT_HOST_START = 'CONTACT_HOST_START';
export const CONTACT_HOST_SUCCESS = 'CONTACT_HOST_SUCCESS';
export const CONTACT_HOST_ERROR = 'CONTACT_HOST_ERROR';

// Send Message
export const SEND_MESSAGE_START = 'SEND_MESSAGE_START';
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_ERROR = 'SEND_MESSAGE_ERROR';

// Update Static Page Details
export const ADMIN_UPDATE_STATIC_START = 'ADMIN_UPDATE_STATIC_START';
export const ADMIN_UPDATE_STATIC_SUCCESS = 'ADMIN_UPDATE_STATIC_SUCCESS';
export const ADMIN_UPDATE_STATIC_ERROR = 'ADMIN_UPDATE_STATIC_ERROR';

// Contact Host Modal
export const CONTACT_HOST_OPEN = 'CONTACT_HOST_OPEN';
export const CONTACT_HOST_CLOSE = 'CONTACT_HOST_CLOSE';

// Read Messages
export const READ_MESSAGE_START = 'READ_MESSAGE_START';
export const READ_MESSAGE_SUCCESS = 'READ_MESSAGE_SUCCESS';
export const READ_MESSAGE_ERROR = 'READ_MESSAGE_ERROR';

// Booking Process
export const BOOKING_PROCESS_START = 'BOOKING_PROCESS_START';
export const BOOKING_PROCESS_SUCCESS = 'BOOKING_PROCESS_SUCCESS';
export const BOOKING_PROCESS_ERROR = 'BOOKING_PROCESS_ERROR';

// Booking Payment
export const BOOKING_PAYMENT_START = 'BOOKING_PAYMENT_START';
export const BOOKING_PAYMENT_SUCCESS = 'BOOKING_PAYMENT_SUCCESS';
export const BOOKING_PAYMENT_ERROR = 'BOOKING_PAYMENT_ERROR';

// Booking Payment - For Cancel from Paypal
export const BOOKING_PAYMENT_FOR_CANCEL_START =
  'BOOKING_PAYMENT_FOR_CANCEL_START';
export const BOOKING_PAYMENT_FOR_CANCEL_SUCCESS =
  'BOOKING_PAYMENT_FOR_CANCEL_SUCCESS';
export const BOOKING_PAYMENT_FOR_CANCEL_ERROR =
  'BOOKING_PAYMENT_FOR_CANCEL_ERROR';

// Payout - Add
export const ADD_PAYOUT_START = 'Add_PAYOUT_START';
export const ADD_PAYOUT_SUCCESS = 'ADD_PAYOUT_SUCCESS';
export const ADD_PAYOUT_ERROR = 'ADD_PAYOUT_ERROR';

// Payout - Remove
export const REMOVE_PAYOUT_START = 'REMOVE_PAYOUT_START';
export const REMOVE_PAYOUT_SUCCESS = 'REMOVE_PAYOUT_SUCCESS';
export const REMOVE_PAYOUT_ERROR = 'REMOVE_PAYOUT_ERROR';

// Payout - Add
export const SET_DEFAULT_PAYOUT_START = 'SET_DEFAULT_PAYOUT_START';
export const SET_DEFAULT_PAYOUT_SUCCESS = 'SET_DEFAULT_PAYOUT_SUCCESS';
export const SET_DEFAULT_PAYOUT_ERROR = 'SET_DEFAULT_PAYOUT_ERROR';

// Transaction History - Change Payout
export const CHANGE_PAYOUT_START = 'CHANGE_PAYOUT_START';
export const CHANGE_PAYOUT_SUCCESS = 'CHANGE_PAYOUT_SUCCESS';
export const CHANGE_PAYOUT_ERROR = 'CHANGE_PAYOUT_ERROR';

// Get Service Fee
export const GET_SERVICE_FEES_START = 'GET_SERVICE_FEES_START';
export const GET_SERVICE_FEES_SUCCESS = 'GET_SERVICE_FEES_SUCCESS';
export const GET_SERVICE_FEES_ERROR = 'GET_SERVICE_FEES_ERROR';

// Host & Guest Actions on Reservation
export const UPDATE_RESERVATION_STATE_START = 'UPDATE_RESERVATION_STATE_START';
export const UPDATE_RESERVATION_STATE_SUCCESS =
  'UPDATE_RESERVATION_STATE_SUCCESS';
export const UPDATE_RESERVATION_STATE_ERROR = 'UPDATE_RESERVATION_STATE_ERROR';

// Host & Guest Actions on Reservation
export const CANCEL_RESERVATION_START = 'CANCEL_RESERVATION_START';
export const CANCEL_RESERVATION_SUCCESS = 'CANCEL_RESERVATION_SUCCESS';
export const CANCEL_RESERVATION_STATE_ERROR = 'CANCEL_RESERVATION_STATE_ERROR';

// Loader Buttons
export const SET_LOADER_START = 'SET_LOADER_START';
export const SET_LOADER_COMPLETE = 'SET_LOADER_COMPLETE';

// Import Calendar
export const IMPORT_CALENDAR_START = 'IMPORT_CALENDAR_START';
export const IMPORT_CALENDAR_SUCCESS = 'IMPORT_CALENDAR_SUCCESS';
export const IMPORT_CALENDAR_ERROR = 'IMPORT_CALENDAR_ERROR';

// Delete Import Calendar
export const DELETE_IMPORT_CALENDAR_START = 'DELETE_IMPORT_CALENDAR_START';
export const DELETE_IMPORT_CALENDAR_SUCCESS = 'DELETE_IMPORT_CALENDAR_SUCCESS';
export const DELETE_IMPORT_CALENDAR_ERROR = 'DELETE_IMPORT_CALENDAR_ERROR';

/**

Constants for SiteAdmin

**/

// View Receipt - Reservation Management
export const ADMIN_VIEW_RECEIPT_START = 'ADMIN_VIEW_RECEIPT_START';
export const ADMIN_VIEW_RECEIPT_SUCCESS = 'ADMIN_VIEW_RECEIPT_SUCCESS';
export const ADMIN_VIEW_RECEIPT_ERROR = 'ADMIN_VIEW_RECEIPT_ERROR';

// Update Service Fees
export const ADMIN_UPDATE_SERVICE_FEES_START =
  'ADMIN_UPDATE_SERVICE_FEES_START';
export const ADMIN_UPDATE_SERVICE_FEES_SUCCESS =
  'ADMIN_UPDATE_SERVICE_FEES_SUCCESS';
export const ADMIN_UPDATE_SERVICE_FEES_ERROR =
  'ADMIN_UPDATE_SERVICE_FEES_ERROR';

// Payout Host - Reservation Management
export const ADMIN_PAYOUT_HOST_START = 'ADMIN_PAYOUT_HOST_START';
export const ADMIN_PAYOUT_HOST_SUCCESS = 'ADMIN_PAYOUT_HOST_SUCCESS';
export const ADMIN_PAYOUT_HOST_ERROR = 'ADMIN_PAYOUT_HOST_ERROR';

// Refund Guest - Reservation Management
export const ADMIN_REFUND_GUEST_START = 'ADMIN_REFUND_GUEST_START';
export const ADMIN_REFUND_GUEST_SUCCESS = 'ADMIN_REFUND_GUEST_SUCCESS';
export const ADMIN_REFUND_GUEST_ERROR = 'ADMIN_REFUND_GUEST_ERROR';

// Delete User from UserManagement
export const ADMIN_DELETE_USER_START = 'ADMIN_DELETE_USER_START';
export const ADMIN_DELETE_USER_SUCCESS = 'ADMIN_DELETE_USER_SUCCESS';
export const ADMIN_DELETE_USER_ERROR = 'ADMIN_DELETE_USER_ERROR';

// Load Listing Settings Data
export const ADMIN_LOAD_LIST_SETTINGS_START = 'ADMIN_LOAD_LIST_SETTINGS_START';
export const ADMIN_LOAD_LIST_SETTINGS_SUCCESS =
  'ADMIN_LOAD_LIST_SETTINGS_SUCCESS';
export const ADMIN_LOAD_LIST_SETTINGS_ERROR = 'ADMIN_LOAD_LIST_SETTINGS_ERROR';

// Listing Settings Modal Open and Close actions
export const OPEN_LIST_SETTINGS_MODAL = 'OPEN_LIST_SETTINGS_MODAL';
export const CLOSE_LIST_SETTINGS_MODAL = 'CLOSE_LIST_SETTINGS_MODAL';

// Delete Listing Settings
export const DELETE_LIST_SETTINGS_START = 'DELETE_LIST_SETTINGS_START';
export const DELETE_LIST_SETTINGS_SUCCESS = 'DELETE_LIST_SETTINGS_SUCCESS';
export const DELETE_LIST_SETTINGS_ERROR = 'DELETE_LIST_SETTINGS_ERROR';

// Enable / Disable Currencies
export const CHANGE_CURRENCY_STATUS_START = 'CHANGE_CURRENCY_STATUS_START';
export const CHANGE_CURRENCY_STATUS_SUCCESS = 'CHANGE_CURRENCY_STATUS_SUCCESS';
export const CHANGE_CURRENCY_STATUS_ERROR = 'CHANGE_CURRENCY_STATUS_ERROR';

// Set Base Currency
export const SET_BASE_CURRENCY_START = 'SET_BASE_CURRENCY_START';
export const SET_BASE_CURRENCY_SUCCESS = 'SET_BASE_CURRENCY_SUCCESS';
export const SET_BASE_CURRENCY_ERROR = 'SET_BASE_CURRENCY_ERROR';

// Upload Logo
export const LOGO_UPLOAD_LOADER_START = 'LOGO_UPLOAD_LOADER_START';
export const LOGO_UPLOAD_LOADER_STOP = 'LOGO_UPLOAD_LOADER_STOP';
export const LOGO_UPLOAD_START = 'LOGO_UPLOAD_START';
export const LOGO_UPLOAD_SUCCESS = 'LOGO_UPLOAD_SUCCESS';
export const LOGO_UPLOAD_ERROR = 'LOGO_UPLOAD_ERROR';

// Upload Home Logo
export const HOME_LOGO_UPLOAD_LOADER_START = 'HOME_LOGO_UPLOAD_LOADER_START';
export const HOME_LOGO_UPLOAD_LOADER_STOP = 'HOME_LOGO_UPLOAD_LOADER_STOP';
export const HOME_LOGO_UPLOAD_START = 'HOME_LOGO_UPLOAD_START';
export const HOME_LOGO_UPLOAD_SUCCESS = 'HOME_LOGO_UPLOAD_SUCCESS';
export const HOME_LOGO_UPLOAD_ERROR = 'LOGO_UPLOAD_ERROR';

// Get Logo
export const GET_LOGO_START = 'GET_LOGO_START';
export const GET_LOGO_SUCCESS = 'GET_LOGO_SUCCESS';
export const GET_LOGO_ERROR = 'GET_LOGO_ERROR';

// Remove Logo
export const REMOVE_LOGO_START = 'REMOVE_LOGO_START';
export const REMOVE_LOGO_SUCCESS = 'REMOVE_LOGO_SUCCESS';
export const REMOVE_LOGO_ERROR = 'REMOVE_LOGO_ERROR';

// Remove Logo
export const REMOVE_HOME_LOGO_START = 'REMOVE_HOME_LOGO_START';
export const REMOVE_HOME_LOGO_SUCCESS = 'REMOVE_HOME_LOGO_SUCCESS';
export const REMOVE_HOME_LOGO_ERROR = 'REMOVE_HOME_LOGO_ERROR';

// Upload Location
export const LOCATION_UPLOAD_LOADER_START = 'LOCATION_UPLOAD_LOADER_START';
export const LOCATION_UPLOAD_START = 'LOCATION_UPLOAD_START';
export const LOCATION_UPLOAD_SUCCESS = 'LOCATION_UPLOAD_SUCCESS';
export const LOCATION_UPLOAD_ERROR = 'LOCATION_UPLOAD_ERROR';

// Remove Location
export const REMOVE_LOCATION_START = 'REMOVE_LOCATION_START';
export const REMOVE_LOCATION_SUCCESS = 'REMOVE_LOCATION_SUCCESS';
export const REMOVE_LOCATION_ERROR = 'REMOVE_LOCATION_ERROR';

// Recommend List

/** Listing Management **/

// Add Recommend
export const ADD_RECOMMEND_START = 'ADD_RECOMMEND_START';
export const ADD_RECOMMEND_SUCCESS = 'ADD_RECOMMEND_SUCCESS';
export const ADD_RECOMMEND_ERROR = 'ADD_RECOMMEND_ERROR';

// Remove Recommend
export const REMOVE_RECOMMEND_START = 'REMOVE_RECOMMEND_START';
export const REMOVE_RECOMMEND_SUCCESS = 'REMOVE_RECOMMEND_SUCCESS';
export const REMOVE_RECOMMEND_ERROR = 'REMOVE_RECOMMEND_ERROR';

// Remove Listing
export const SITE_ADMIN_REMOVE_LISTING_START =
  'SITE_ADMIN_REMOVE_LISTING_START';
export const SITE_ADMIN_REMOVE_LISTING_SUCCESS =
  'SITE_ADMIN_REMOVE_LISTING_SUCCESS';
export const SITE_ADMIN_REMOVE_LISTING_ERROR =
  'SITE_ADMIN_REMOVE_LISTING_ERROR';

/** Listing Management **/

// Upate Banner Form
export const UPDATE_IMAGE_BANNER_START = 'UPDATE_IMAGE_BANNER_START';
export const UPDATE_IMAGE_BANNER_SUCCESS = 'UPDATE_IMAGE_BANNER_SUCCESS';
export const UPDATE_IMAGE_BANNER_ERROR = 'UPDATE_IMAGE_BANNER_ERROR';

// Upate Banner Form
export const IMAGE_BANNER_UPLOAD_LOADER_START =
  'IMAGE_BANNER_UPLOAD_LOADER_START';
export const IMAGE_BANNER_UPLOAD_LOADER_STOP =
  'IMAGE_BANNER_UPLOAD_LOADER_STOP';
export const UPLOAD_IMAGE_BANNER_START = 'UPLOAD_IMAGE_BANNER_START';
export const UPLOAD_IMAGE_BANNER_SUCCESS = 'UPLOAD_IMAGE_BANNER_SUCCESS';
export const UPLOAD_IMAGE_BANNER_ERROR = 'UPLOAD_IMAGE_BANNER_ERROR';

// Upate Banner Form
export const REMOVE_IMAGE_BANNER_START = 'REMOVE_IMAGE_BANNER_START';
export const REMOVE_IMAGE_BANNER_SUCCESS = 'REMOVE_IMAGE_BANNER_SUCCESS';
export const REMOVE_IMAGE_BANNER_ERROR = 'REMOVE_IMAGE_BANNER_ERROR';

// Upate Banner Form
export const UPDATE_HOME_BANNER_START = 'UPDATE_HOME_BANNER_START';
export const UPDATE_HOME_BANNER_SUCCESS = 'UPDATE_HOME_BANNER_SUCCESS';
export const UPDATE_HOME_BANNER_ERROR = 'UPDATE_HOME_BANNER_ERROR';

// Upate Banner Form
export const HOME_BANNER_UPLOAD_LOADER_START =
  'HOME_BANNER_UPLOAD_LOADER_START';
export const HOME_BANNER_UPLOAD_LOADER_STOP = 'HOME_BANNER_UPLOAD_LOADER_STOP';
export const UPLOAD_HOME_BANNER_START = 'UPLOAD_HOME_BANNER_START';
export const UPLOAD_HOME_BANNER_SUCCESS = 'UPLOAD_HOME_BANNER_SUCCESS';
export const UPLOAD_HOME_BANNER_ERROR = 'UPLOAD_HOME_BANNER_ERROR';

// Upate Banner Form
export const REMOVE_HOME_BANNER_START = 'REMOVE_HOME_BANNER_START';
export const REMOVE_HOME_BANNER_SUCCESS = 'REMOVE_HOME_BANNER_SUCCESS';
export const REMOVE_HOME_BANNER_ERROR = 'REMOVE_HOME_BANNER_ERROR';

// MANAGE PAYMENT CURRENCY - SITE ADMIN
export const ADMIN_MANAGE_PAYMENT_CURRENCY_START =
  'ADMIN_MANAGE_PAYMENT_CURRENCY_START';
export const ADMIN_MANAGE_PAYMENT_CURRENCY_SUCCESS =
  'ADMIN_MANAGE_PAYMENT_CURRENCY_SUCCESS';
export const ADMIN_MANAGE_PAYMENT_CURRENCY_ERROR =
  'ADMIN_MANAGE_PAYMENT_CURRENCY_ERROR';

// Site Admin - Reservation Modal
export const ADMIN_RESERVATION_MODAL_SHOW = 'ADMIN_RESERVATION_MODAL_SHOW';
export const ADMIN_RESERVATION_MODAL_HIDE = 'ADMIN_RESERVATION_MODAL_HIDE';

// Feature Alert
export const FEATURE_ALERT_MODAL_SHOW = 'FEATURE_ALERT_MODAL_SHOW';
export const FEATURE_ALERT_MODAL_HIDE = 'FEATURE_ALERT_MODAL_HIDE';

// Admin Reviews
export const ADMIN_DELETE_REVIEW_START = 'ADMIN_DELETE_REVIEW_START';
export const ADMIN_DELETE_REVIEW_SUCCESS = 'ADMIN_DELETE_REVIEW_SUCCESS';
export const ADMIN_DELETE_REVIEW_ERROR = 'ADMIN_DELETE_REVIEW_ERROR';

// Admin PopularLocation
export const ADMIN_DELETE_POPULARLOCATION_START =
  'ADMIN_DELETE_POPULARLOCATION_START';
export const ADMIN_DELETE_POPULARLOCATION_SUCCESS =
  'ADMIN_DELETE_POPULARLOCATION_SUCCESS';
export const ADMIN_DELETE_POPULARLOCATION_ERROR =
  'ADMIN_DELETE_POPULARLOCATION_ERROR';

// WishList Group ADD & EDIT
export const OPEN_WISH_LIST_GROUP_MODAL = 'OPEN_WISH_LIST_GROUP_MODAL';
export const CLOSE_WISH_LIST_GROUP_MODAL = 'CLOSE_WISH_LIST_GROUP_MODAL';

// Delete Wish List Group
export const DELETE_WISH_LIST_GROUP_START = 'DELETE_WISH_LIST_GROUP_START';
export const DELETE_WISH_LIST_GROUP_SUCCESS = 'DELETE_WISH_LIST_GROUP_SUCCESS';
export const DELETE_WISH_LIST_GROUP_ERROR = 'DELETE_WISH_LIST_GROUP_ERROR';

// WishList Modal
export const OPEN_WISH_LIST_MODAL = 'OPEN_WISH_LIST_MODAL';
export const CLOSE_WISH_LIST_MODAL = 'CLOSE_WISH_LIST_MODAL';

// Sticky Book Section
export const SET_STICKY_TOP = 'SET_STICKY_TOP';
export const SET_STICKY_BOTTOM = 'SET_STICKY_BOTTOM';

// Report user modal
export const OPEN_REPORT_USER_MODAL = 'OPEN_REPORT_USER_MODAL';
export const CLOSE_REPORT_USER_MODAL = 'CLOSE_REPORT_USER_MODAL';

// Report user Thank you modal
export const OPEN_THANK_YOU_MODAL = 'OPEN_THANK_YOU_MODAL';
export const CLOSE_THANK_YOU_MODAL = 'CLOSE_THANK_YOU_MODAL';

// SMS Verification Modal
export const OPEN_SMS_VERIFICATION_MODAL = 'OPEN_SMS_VERIFICATION_MODAL';
export const CLOSE_SMS_VERIFICATION_MODAL = 'CLOSE_SMS_VERIFICATION_MODAL';

// SEND VERIFICATION SMS
export const SEND_VERIFICATION_SMS_START = 'SEND_VERIFICATION_SMS_START';
export const SEND_VERIFICATION_SMS_SUCCESS = 'SEND_VERIFICATION_SMS_SUCCESS';
export const SEND_VERIFICATION_SMS_ERROR = 'SEND_VERIFICATION_SMS_ERROR';

// Listing History - OnChange Listing
export const ON_CHANGE_LISTING_START = 'ON_CHANGE_LISTING_START';

// Payout History - Onchange
export const ON_CHANGE_PAYOUT_START = 'ON_CHANGE_PAYOUT_START';

//Reset ListPlaceStep
export const RESET_LISTING_STEPS_DATA_START = 'RESET_LISTING_STEPS_DATA_START';
export const RESET_LISTING_STEPS_DATA_SUCCESS =
  'RESET_LISTING_STEPS_DATA_SUCCESS';
export const RESET_LISTING_STEPS_DATA_ERROR = 'RESET_LISTING_STEPS_DATA_ERROR';

// Get blocked dates
export const GET_BLOCKED_START = 'GET_BLOCKED_START';
export const GET_BLOCKED_SUCCESS = 'GET_BLOCKED_SUCCESS';
export const GET_BLOCKED_ERROR = 'GET_BLOCKED_ERROR';

// GET Listing Special Pricing
export const GET_LISTING_SPECIAL_PRICING_START =
  'GET_LISTING_SPECIAL_PRICING_START';
export const GET_LISTING_SPECIAL_PRICING_SUCCESS =
  'GET_LISTING_SPECIAL_PRICING_SUCCESS';
export const GET_LISTING_SPECIAL_PRICING_ERROR =
  'GET_LISTING_SPECIAL_PRICING_ERROR';

// View Listing - Booking window
export const OPEN_BOOKING_MODAL = 'OPEN_BOOKING_MODAL';
export const CLOSE_BOOKING_MODAL = 'CLOSE_BOOKING_MODAL';

// User Add Review
export const UPDATE_REVIEW_START = 'UPDATE_REVIEW_START';
export const UPDATE_REVIEW_SUCCESS = 'UPDATE_REVIEW_SUCCESS';
export const UPDATE_REVIEW_ERROR = 'UPDATE_REVIEW_ERROR';

// User Remove Review
export const REMOVE_REVIEW_START = 'REMOVE_REVIEW_START';
export const REMOVE_REVIEW_SUCCESS = 'REMOVE_REVIEW_SUCCESS';
export const REMOVE_REVIEW_ERROR = 'REMOVE_REVIEW_ERROR';
// GET Listing Step Two
export const GET_LISTING_DETAILS_START = 'GET_LISTING_DETAILS_START';
export const GET_LISTING_DETAILS_SUCCESS = 'GET_LISTING_DETAILS_SUCCESS';
export const GET_LISTING_DETAILS_ERROR = 'GET_LISTING_DETAILS_ERROR';

// Static block info
export const STATIC_BLOCK_INFO_START = 'STATIC_BLOCK_INFO_START';
export const STATIC_BLOCK_INFO_SUCCESS = 'STATIC_BLOCK_INFO_SUCCESS';
export const STATIC_BLOCK_INFO_ERROR = 'STATIC_BLOCK_INFO_ERROR';

//Social Share modal
export const OPEN_SOCIAL_SHARE_MODAL = 'OPEN_SOCIAL_SHARE_MODAL';
export const CLOSE_SOCIAL_SHARE_MODAL = 'CLOSE_SOCIAL_SHARE_MODAL';

// Car Details
export const CAR_LOAD_LIST_SETTINGS_START = 'CAR_LOAD_LIST_SETTINGS_START';
export const CAR_LOAD_LIST_SETTINGS_SUCCESS = 'CAR_LOAD_LIST_SETTINGS_SUCCESS';
export const CAR_LOAD_LIST_SETTINGS_ERROR = 'CAR_LOAD_LIST_SETTINGS_ERROR';

// car Model Details
export const CAR_MODEL_LOAD_LIST_SETTINGS_START =
  'CAR_MODEL_LOAD_LIST_SETTINGS_START';
export const CAR_MODEL_LOAD_LIST_SETTINGS_SUCCESS =
  'CAR_MODEL_LOAD_LIST_SETTINGS_SUCCESS';
export const CAR_MODEL_LOAD_LIST_SETTINGS_ERROR =
  'CAR_MODEL_LOAD_LIST_SETTINGS_ERROR';

// Payout - Verify
export const VERIFY_PAYOUT_START = 'VERIFY_PAYOUT_START';
export const VERIFY_PAYOUT_SUCCESS = 'VERIFY_PAYOUT_SUCCESS';
export const VERIFY_PAYOUT_ERROR = 'VERIFY_PAYOUT_ERROR';

// Payout - GET
export const GET_PAYOUT_START = 'GET_PAYOUT_START';
export const GET_PAYOUT_SUCCESS = 'GET_PAYOUT_SUCCESS';
export const GET_PAYOUT_ERROR = 'GET_PAYOUT_ERROR';

// Upload Static block banner
export const STATIC_BLOCK_IMAGE_UPLOAD_START =
  'STATIC_BLOCK_IMAGE_UPLOAD_START';
export const STATIC_BLOCK_IMAGE2_UPLOAD_START =
  'STATIC_BLOCK_IMAGE2_UPLOAD_START';
export const STATIC_BLOCK2_IMAGE_UPLOAD_START =
  'STATIC_BLOCK2_IMAGE_UPLOAD_START';
export const STATIC_BLOCK_IMAGE_START = 'STATIC_BLOCK_IMAGE_START';
export const STATIC_BLOCK_IMAGE_SUCCESS = 'STATIC_BLOCK_IMAGE_SUCCESS';
export const STATIC_BLOCK_IMAGE2_SUCCESS = 'STATIC_BLOCK_IMAGE2_SUCCESS';
export const STATIC_BLOCK_IMAGE_ERROR = 'STATIC_BLOCK_IMAGE_ERROR';

// Remove Static image info
export const REMOVE_STATIC_INFO_IMAGE_START = 'REMOVE_STATIC_INFO_IMAGE_START';
export const REMOVE_STATIC_INFO_IMAGE_SUCCESS =
  'REMOVE_STATIC_INFO_IMAGE_SUCCESS';
export const REMOVE_STATIC_INFO_IMAGE_ERROR = 'REMOVE_STATIC_INFO_IMAGE_ERROR';

// Upate Banner Form
export const GET_HOME_BANNER_START = 'GET_HOME_BANNER_START';
export const GET_HOME_BANNER_SUCCESS = 'GET_HOME_BANNER_SUCCESS';
export const GET_HOME_BANNER_ERROR = 'GET_HOME_BANNER_ERROR';

// upload Static Info Block images
export const UPLOAD_STATIC_INFO_BLOCK_IMAGE_START =
  'UPLOAD_STATIC_INFO_BLOCK_IMAGE_START';
export const UPLOAD_STATIC_INFO_BLOCK_IMAGE_SUCCESS =
  'UPLOAD_STATIC_INFO_BLOCK_IMAGE_SUCCESS';
export const UPLOAD_STATIC_INFO_BLOCK_IMAGE2_SUCCESS =
  'UPLOAD_STATIC_INFO_BLOCK_IMAGE2_SUCCESS';
export const UPLOAD_STATIC_INFO_BLOCK_IMAGE_ERROR =
  'UPLOAD_STATIC_INFO_BLOCK_IMAGE_ERROR';
export const STATIC_INFO_BLOCK_UPLOAD_LOADER_START =
  'STATIC_INFO_BLOCK_UPLOAD_LOADER_START';
export const STATIC_INFO_BLOCK2_UPLOAD_LOADER_START =
  'STATIC_INFO_BLOCK_UPLOAD_LOADER_START';

export const DELETE_STATIC_INFO_IMAGE_START = 'DELETE_STATIC_INFO_IMAGE_START';
export const DELETE_STATIC_INFO_IMAGE_SUCCESS =
  'DELETE_STATIC_INFO_IMAGE_SUCCESS';
export const DELETE_STATIC_INFO_IMAGE_ERROR = 'DELETE_STATIC_INFO_IMAGE_ERROR';

export const CARBLOCK_IMAGE_UPLOAD_START = 'CARBLOCK_IMAGE_UPLOAD_START';
export const COUNTERBLOCK_IMAGE_UPLOAD_START =
  'COUNTERBLOCK_IMAGE_UPLOAD_START';
export const CARBLOCK_IMAGE_UPLOAD_SUCCESS = 'CARBLOCK_IMAGE_UPLOAD_SUCCESS';
export const COUNTERBLOCK_IMAGE_UPLOAD_SUCCESS =
  'COUNTERBLOCK_IMAGE_UPLOAD_SUCCESS';

export const IMAGE_LOADER1_START = 'IMAGE_LOADER1_START';
export const IMAGE_LOADER1_SUCCESS = 'IMAGE_LOADER1_SUCCESS';

//set payout status
export const SET_PAYOUT_STATUS_START = 'SET_PAYOUT_STATUS_START';
export const SET_PAYOUT_STATUS_SUCCESS = 'SET_PAYOUT_STATUS_SUCCESS';
export const SET_PAYOUT_STATUS_ERROR = 'SET_PAYOUT_STATUS_START';

export const SET_PAYMENT_GATEWAY_STATUS_START =
  'SET_PAYMENT_GATEWAY_STATUS_START';
export const SET_PAYMENT_GATEWAY_STATUS_SUCCESS =
  'SET_PAYMENT_GATEWAY_STATUS_SUCCESS';
export const SET_PAYMENT_GATEWAY_STATUS_ERROR =
  'SET_PAYMENT_GATEWAY_STATUS_START';

// HEADER MODAL
export const OPEN_HEADER_MODAL = 'OPEN_HEADER_MODAL';
export const CLOSE_HEADER_MODAL = 'CLOSE_HEADER_MODAL';

// Logout
export const ADMIN_USER_LOGOUT_START = 'ADMIN_USER_LOGOUT_START';
export const ADMIN_USER_LOGOUT_SUCCESS = 'ADMIN_USER_LOGOUT_SUCCESS';
export const ADMIN_USER_LOGOUT_ERROR = 'ADMIN_USER_LOGOUT_ERROR';

// ADMIN ROLES MODAL
export const OPEN_ADMIN_ROLES_MODAL = 'OPEN_ADMIN_ROLES_MODAL';
export const CLOSE_ADMIN_ROLES_MODAL = 'CLOSE_ADMIN_ROLES_MODAL';

// Create Admin Role
export const CREATE_ADMIN_ROLES_START = 'CREATE_ADMIN_ROLES_START';
export const CREATE_ADMIN_ROLES_SUCCESS = 'CREATE_ADMIN_ROLES_SUCCESS';
export const CREATE_ADMIN_ROLES_ERROR = 'CREATE_ADMIN_ROLES_ERROR';

// Delete Admin Role
export const DELETE_ADMIN_ROLES_START = 'DELETE_ADMIN_ROLES_START';
export const DELETE_ADMIN_ROLES_SUCCESS = 'DELETE_ADMIN_ROLES_SUCCESS';
export const DELETE_ADMIN_ROLES_ERROR = 'DELETE_ADMIN_ROLES_ERROR';

// Create Admin User
export const CREATE_ADMIN_USER_START = 'CREATE_ADMIN_USER_START';
export const CREATE_ADMIN_USER_SUCCESS = 'CREATE_ADMIN_USER_SUCCESS';
export const CREATE_ADMIN_USER_ERROR = 'CREATE_ADMIN_USER_ERROR';

// Delete Admin User
export const DELETE_ADMIN_USER_START = 'DELETE_ADMIN_USER_START';
export const DELETE_ADMIN_USER_SUCCESS = 'DELETE_ADMIN_USER_SUCCESS';
export const DELETE_ADMIN_USER_ERROR = 'DELETE_ADMIN_USER_ERROR';

// ADMIN ROLES MODAL
export const OPEN_ADMIN_USER_MODAL = 'OPEN_ADMIN_USER_MODAL';
export const CLOSE_ADMIN_USER_MODAL = 'CLOSE_ADMIN_USER_MODAL';

// Get Admin privileges
export const ADMIN_PRIVILEGES_START = 'ADMIN_PRIVILEGES_START';
export const ADMIN_PRIVILEGES_SUCCESS = 'ADMIN_PRIVILEGES_SUCCESS';
export const ADMIN_PRIVILEGES_ERROR = 'ADMIN_PRIVILEGES_ERROR';

// Get Admin user
export const GET_ADMIN_USER_START = 'GET_ADMIN_USER_START';
export const GET_ADMIN_USER_SUCCESS = 'GET_ADMIN_USER_SUCCESS';
export const GET_ADMIN_USER_ERROR = 'GET_ADMIN_USER_ERROR';

// Upload Email Logo
export const EMAIL_LOGO_UPLOAD_LOADER_START = 'EMAIL_LOGO_UPLOAD_LOADER_START';
export const EMAIL_LOGO_UPLOAD_START = 'EMAIL_LOGO_UPLOAD_START';
export const EMAIL_LOGO_UPLOAD_SUCCESS = 'EMAIL_LOGO_UPLOAD_SUCCESS';
export const EMAIL_LOGO_UPLOAD_ERROR = 'EMAIL_LOGO_UPLOAD_ERROR';

// Remove Logo
export const REMOVE_EMAIL_LOGO_START = 'REMOVE_EMAIL_LOGO_START';
export const REMOVE_EMAIL_LOGO_SUCCESS = 'REMOVE_EMAIL_LOGO_SUCCESS';
export const REMOVE_EMAIL_LOGO_ERROR = 'REMOVE_EMAIL_LOGO_ERROR';

// Update Favicon logo
export const UPDATE_FAVICON_LOGO_START = 'UPDATE_FAVICON_LOGO_START';
export const UPDATE_FAVICON_LOGO_SUCCESS = 'UPDATE_FAVICON_LOGO_SUCCESS';
export const UPDATE_FAVICON_LOGO_ERROR = 'UPDATE_FAVICON_LOGO_ERROR';

//Color code
export const COMMON_COLOR = '#2C666E';
export const COMMON_TEXT_COLOR = '#080A1C';
export const TOGGLE_ON_COLOR = '#1852D8';
export const START_FILL_COLOR = '#FFA207';

// Upate Find your car block form
export const UPDATE_CAR_BLOCK_START = 'UPDATE_CAR_BLOCK_START';
export const UPDATE_CAR_BLOCK_SUCCESS = 'UPDATE_CAR_BLOCKSUCCESS';
export const UPDATE_CAR_BLOCK_ERROR = 'UPDATE_CAR_BLOCK_ERROR';

// Upate Banner Form
export const GET_HOME_START = 'GET_HOME_START';
export const GET_HOME_SUCCESS = 'GET_HOME_SUCCESS';
export const GET_HOME_ERROR = 'GET_HOME_ERROR';

export const UPDATE_CONFIG_SETTINGS_START = 'UPDATE_CONFIG_SETTINGS_START';
export const UPDATE_CONFIG_SETTINGS_SUCCESS = 'UPDATE_CONFIG_SETTINGS_SUCCESS';
export const UPDATE_CONFIG_SETTINGS_ERROR = 'UPDATE_CONFIG_SETTINGS_ERROR';

export const OPEN_FILTER_MODAL = 'OPEN_FILTER_MODAL';
export const CLOSE_FILTER_MODAL = 'CLOSE_FILTER_MODAL';

export const ON_CHANGE_LISTING_FILTER_START = 'ON_CHANGE_LISTING_FILTER_START';

export const OPEN_TRANSACTION_FILTER_MODAL = 'OPEN_TRANSACTION_FILTER_MODAL';
export const CLOSE_TRANSACTION_FILTER_MODAL = 'CLOSE_TRANSACTION_FILTER_MODAL';

//Host Document
export const WHYHOST_UPDATE_START = 'WHYHOST_UPDATE_START';
export const WHYHOST_UPDATE_SUCCESS = 'WHYHOST_UPDATE_SUCCESS';
export const WHYHOST_UPDATE_ERROR = 'WHYHOST_UPDATE_ERROR';

export const CLAIM_PAYOUT_START = 'CLAIM_PAYOUT_START';
export const CLAIM_PAYOUT_SUCCESS = 'CLAIM_PAYOUT_SUCCESS';
export const CLAIM_PAYOUT_ERROR = 'CLAIM_PAYOUT_ERROR';

export const IMPORT_CSV_FILE_START = 'IMPORT_CSV_FILE_START';
export const IMPORT_CSV_FILE_SUCCESS = 'IMPORT_CSV_FILE_SUCCESS';
export const IMPORT_CSV_FILE_ERROR = 'IMPORT_CSV_FILE_ERROR';

//More Filters Modal
export const OPEN_MORE_FILTERS_MODAL = 'OPEN_MORE_FILTERS_MODAL';
export const CLOSE_MORE_FILTERS_MODAL = 'CLOSE_MORE_FILTERS_MODAL';

//Verification Documents

export const CREATE_VERIFICATION_DOCUMENTS_START =
  'CREATE_VERIFICATION_DOCUMENTS_START';
export const CREATE_VERIFICATION_DOCUMENTS_SUCCESS =
  'CREATE_VERIFICATION_DOCUMENTS_SUCCESS';
export const CREATE_VERIFICATION_DOCUMENTS_ERROR =
  'CREATE_VERIFICATION_DOCUMENTS_ERROR';
export const REMOVE_VERIFICATION_DOCUMENTS_START =
  'REMOVE_VERIFICATION_DOCUMENTS_START';
export const REMOVE_VERIFICATION_DOCUMENTS_SUCCESS =
  'REMOVE_VERIFICATION_DOCUMENTS_SUCCESS';
export const REMOVE_VERIFICATION_DOCUMENTS_ERROR =
  'REMOVE_VERIFICATION_DOCUMENTS_ERROR';
export const GET_VERIFICATION_DOCUMENTS_START =
  'GET_VERIFICATION_DOCUMENTS_START';
export const GET_VERIFICATION_DOCUMENTS_SUCCESS =
  'GET_VERIFICATION_DOCUMENTS_SUCCESS';
export const GET_VERIFICATION_DOCUMENTS_ERROR =
  'GET_VERIFICATION_DOCUMENTS_ERROR';
