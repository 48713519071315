exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".EditWishListGroupForm-marginLeft-2bJsn {\n    margin-left: 12px;\n}\n\n@media screen and (max-width: 767px) {\n    .EditWishListGroupForm-mobileBtn-1z1aJ {\n        width: 100%;\n    }\n\n    .EditWishListGroupForm-marginLeft-2bJsn {\n        margin-left: 0;\n        margin-top: 12px;\n    }\n}", ""]);

// exports
exports.locals = {
	"marginLeft": "EditWishListGroupForm-marginLeft-2bJsn",
	"mobileBtn": "EditWishListGroupForm-mobileBtn-1z1aJ"
};