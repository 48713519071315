exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".EmptyList-noDataIconSec-QvkoF {\n    width: 184px;\n    height: 120px;\n}\n\n.EmptyList-noTransactionSectionSpace-2e7Us {\n    margin-top: 66px;\n    margin-bottom: 22px;\n}\n\n.EmptyList-noPayoutContentSection-30i3H {\n    max-width: 580px;   \n}\n\n@media screen and (max-width:767px) {\n    .EmptyList-noPayoutContentSection-30i3H {\n        max-width: 100%\n    }\n}", ""]);

// exports
exports.locals = {
	"noDataIconSec": "EmptyList-noDataIconSec-QvkoF",
	"noTransactionSectionSpace": "EmptyList-noTransactionSectionSpace-2e7Us",
	"noPayoutContentSection": "EmptyList-noPayoutContentSection-30i3H"
};