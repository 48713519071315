exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".WishListGroupForm-marginLeft-3R2qy{\n    margin-left: 12px;\n}", ""]);

// exports
exports.locals = {
	"marginLeft": "WishListGroupForm-marginLeft-3R2qy"
};