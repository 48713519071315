const errorMessage = (errorcode, error) => {

    const message = {
        ogImageError: 'Please upload the OG  Image',
        notUpdated: 'Not Updated',
        notLoggedIn: 'Not LoggedIn',
        listingNotAvailable: "Listing is not available",
        notAvailable: 'notAvailable',
        updateStatusError: "Oops! Couldn't update the status. Please try again.",
        loginError: "Oops! Please log in and continue.",
        catchError: `Oops! Something went wrong." ${error}`,
        createReservationError: 'Failed to create a reservation.',
        createPayoutError: 'Failed to create a payout.',
        deleteRecordError: 'Error in deleting a record.',
        commonError: 'Oops! something went wrong. Please try again.',
        adminRoleError: 'Sorry, unable to find this admin role.',
        deleteAdminRoleError: 'Oops! Unable to delete the admin role due to some admin user using this role.',
        invalidAdminRole: 'Oops, it looks like the chosen role is not valid. Please try selecting a different valid role.',
        checkEmail: 'Oops! This email address already exists.',
        noDocumentsFound: 'No documents found. Please try again.',
        userLoginAccount: "Oops! Please login with your account and try again.",
        updateReviewError: "Something went wrong! Couldn't update the review. Please try again later.",
        somethingWrong: 'Something went wrong. Please try again',
        invalidRequest: 'Invalid request',
        chooseOption: 'At least one option must be active.',
        noRecord: "Something went wrong! No records found. Please try again.",
        userLogin: "Something went wrong! Please login and try again.",
        findActiveReservation: 'Oops! Unable to delete this user. It seems the user is having active/upcoming bookings and they have to cancel or complete the bookings in order to delete them.',
        createThreadItemFailed: 'Failed to create thread items.',
        createThreadFailed: 'Failed to create a thread.',
        checkUserExist: 'Oops! The user is different.',
        adminLogin: 'Please login as an admin user and try again.',
        noRecordFound: "No record found.",
        fetchRecordsError: "Something went wrong on fetching records.Please try again.",
        userLoggedIn: "You are not logged in. Please log in and try again.",
        checkListSettings: "Oops! Unable to find the list settings. Please try again.",
        userNotLoggedIn: "User Not Logged in",
        userLoginError: 'Oops! Please login and continue.',
        wrongConfirmPassword: "WrongConfirmPassword",
        wrongPassword: 'WrongPassword',
        updateFailed: 'updateFailed',
        userLoginExist: 'Please login with your account',
        invalidError: 'Something went wrong',
        checkUserLogin: "Please login",
        userBan: 'userBanned',
        adminLoginError: 'Please login as an admin and try again',
        unableToProceed: 'Oops! Unable to proceed with the requested action. Please try again.',
        errorLogin: 'Please login and continue',
        userNotExist: 'NoUserExist',
        userExist: 'UserExist',
        userUnBan: 'userUnbanned',
        userNotDeleted: 'userNotDeleted',
        userDeleted: 'userDeleted',
        adminLoggedIn: 'adminLoggedIn',
        login: 'loggedIn',
        unknownError: 'Sorry, something went wrong. Please try again',
        //Toaster Error Message
        success: 'Success',
        error: 'Error!',
        warning: 'Warning!',
        forgotPassword: `There is no account associated with the provided email address.`,
        tryAgain: `Something went wrong, please try again later`,
        resetPasswordLink: `We've sent a reset password link to your email. Please check your inbox!.`,
        contactHost: `Your request has been sent to the owner.`,
        updatePassword: `You have successfully updated your password. You can now try to log in!`,
        confirmPasswordError: `Confirmed password does not match the new password.`,
        passwordUpdateSuccess: `Password has been successfully updated!`,
        incorrectPassword: `Current password is incorrect. Please try again.`,
        userNotLogin: `You are not logged in`,
        currentPassword: `Current password is incorrect. Please try again.`,
        changePasswordError: `Sorry, something went wrong! Please reload this page!`,
        payoutStatusSuccess: `The status has been updated.`,
        payoutStatusFailed: `Failed to update status.`,
        invalidAddress: `Your address seems to be invalid, please check & edit your address!`,
        failedPayout: `Payment to the owner has failed. Please try again with a different currency.`,
        successPayout: `Payment has been successfully transferred to the owner.`,
        paymentToOwnerFailed: `Payment to the owner has failed. Please try again with a different currency.`,
        serviceFeeUpdate: `Service fees has been successfully updated!`,
        serviceFeeUpdateFailed: `The update of service fees has failed!`,
        deleteBlogDetails: `Blog details have been deleted.`,
        blogDeletionFailed: `Blog deletion failed!`,
        updateBlogStatus: `Status has been successfully changed!`,
        updateBlogStatusFailed: `Failed to change status`,
        updateBlogSuccess: `Blog changes have been successfully updated!`,
        deleteListing: `Setting has been deleted.`,
        unableToDisableListSetting: `Deletion of the list setting is not possible. There must be at least one list setting available for the cars.`,
        unableToDeleteListSetting: `Deletion of the list settings is not possible. The list setting is used on the property. Please remove the list setting on the car and try again.`,
        listSettingModelUsed: `Deletion of the 'Make' is not possible. The 'Make' is meant to create a model. Please remove the model from the cars and try again.`,
        updateListSettings: `Oops! Something went wrong. Please try again.`,
        deletePopularLocation: `Popular location has been successfully deleted!`,
        deletePopularLocationFailed: `Popular Locations deletion failed!`,
        locationStatusChanged: `Location status has been successfully changed!`,
        locationStatusFailed: `Failed to change location status.`,
        updateFindYourBlock: `Changes have been updated.`,
        updateBannerSettings: `Changes have been successfully updated!`,
        updateBannerSettingsFailed: `Failed to Update Banner Settings`,
        updateBanStatus: `Ban Status has been successfully updated.`,
        selectBanOrUnBan: `You have to select either the option to ban or unBan.`,
        updateBanStatusFailed: `Failed to update ban status.`,
        paymentGatewayStatus: `At least one payment gateway must be active.`,
        stripePaymentGateway: `Stripe has been ${error} successfully`,
        paypalPaymentGateway: `Paypal has been ${error} successfully`,
        paymentGatewayStatusFailed: `Failed to update status.`,
        updateStaticPageSuccess: `Changes have been updated.`,
        updateStaticPageFailed: `Updating failed`,
        updateStaticPageStatus: `Failed to change status`,
        deleteUser: `User has been successfully deleted!`,
        deleteUserFailed: `User deletion failed!`,
        deleteUserDetails: error,
        updateWhyHost: `Why become owner page changes has been successfully updated!`,
        commonError: error,
        noDataFound: `No data found in the file`,
        importCsvFileError: `Oops, Something went wrong please try again later`,
        invalidSpecialPrice: `Special price is invalid.`,
        invalidImageFile: `You are trying to upload invalid image file. Please upload PNG, JPG & JPEG format image file.`,
        deleteReview: `Review has been successfully deleted!`,
        deleteReviewFailed: `Review deletion failed!`,
        addUpdateAdminRole: `Admin Role has been  ${error ? 'updated' : 'added'} successfully!`,
        adminRoleError: `Oops, something went wrong"  ${error}`,
        deleteAdminRole: `Admin Role has been successfully deleted!`,
        addUpdateAdminUser: `Admin User has been ${error ? 'updated' : 'added'} successfully!`,
        adminUserError: `Oops, something went wrong" ${error} `,
        deleteAdminUser: `Admin User has been successfully deleted!`,
        updateConfigSettings: `Configurations has been successfully updated!`,
        catchError: `Oops! Something went wrong, ${error}`,
        setDefaultBaseCurrency: `Default base Currency modified successfully`,
        setDefaultBaseCurrencyFailed: `Failed to set base currency.`,
        disableBaseCurrencyError: `Sorry, you can't disable the current base currency. Please set a different base currency and then disable this one.`,
        currencyStatusSuccess: `Currency status has been successfully changed!`,
        currencyStatusFailed: `Failed to change currency status.`,
        managePaymentCurrencySuccess: `Payment Currency Changes has been successfully updated!`,
        managePaymentCurrencyFailed: `Failed to change payment currency status.`,
        removeRecommend: `List has been successfully removed from the Recommended list`,
        addRecommendList: `The list has been successfully added to the Recommended list.`,
        removeListingSuccess: `List has been successfully removed`,
        unpublishListing: `You cannot unpublish as you have upcoming bookings or enquiries for this listing.`,
        userReviewUpdated: `Review has been successfully updated.`,
        checkTermsAndPolicy: `Please check the terms and privacy policy to proceed further.`,
        updatePopularLocation: `Location changes has been successfully updated!`,
        updatePopularLocationFailed: `Updating location failed`,
        addBlog: `The Blog has been successfully added!`,
        addBlogFailed: `The page URL already exists!`,
        failedToCreateBlog: `Failed to create`,
        updateBlogSuccess: `Blog changes has been successfully updated!`,
        failedToUpdateBlog: `Updating blog failed`,
        addedLocation: `Location has been successfully added!`,
        addedLocationFailed: `Failed to create`,
        updateProfile: `Your changes have been updated!`,
        commonSuccess: error,
        bookingFailed: `Those dates are not available!`,
        alreadyPerformedAction: `You have already performed this action!`,
        failedError: error,
        invalidError: error,
        adminAccessDetails: `Admin access details has been changed successfully!`,
        listingUnPublish: `The listing has been unpublished or deleted by the owner/admin. Please try another.`,
        removePhotosListing: `You cannot remove photos as this listing has upcoming bookings or enquiries!`,
        confirmationEmail: `We've sent a confirmation link to your email.`,
        sentEmailFailed: `Sorry, something went wrong. Please try again!`,
        listingFailed: `${error}  is failed!`,
        reservationCancelled: `Your reservation has been Canceled.`,
        tripCancelled: `Your trip has Canceled.`,
        cancelReservationSuccess: `Your reservation has already been updated!`,
        documentManagementError: `Something went wrong! ${error}`,
        importCalendar: `Your calendar sync process is started!`,
        calendarAlreadyExists: `Your calendar has already been imported!`,
        invalidCalendar: `URL is invalid, and the calendar import has failed.`,
        maximumUploadSize: `Maximum upload size exceeded!,Try with smallest size image`,
        calendarRemoved: `Your calendar has been removed!`,
        deleteCalendarFailed: `Your calendar was not successfully deleted. Please try again.`,
        payoutToHost: `Claim amount has been successfully transferred to host!`,
        paymentToHostFailed: `Payment to the Owner has failed, please try again with a different currency`,
        paymentToHost: `Payment to the Owner has failed, please try again`,
        addWishListGroupForm: error,
        importDuplicateItems: `It seems like there are some duplicate items in the import file, but the new entries have been successfully imported.`,
        formError: `Sorry, something went wrong. Please try again!`,
        updateBlockedDates: `Oops! You are not allowed to update those dates since some of the dates are having an active reservation.`,
        removeBlockedDates: `Those dates are not available!`,
        uploadDocument: `Document has been successfully uploaded!`,
        uploadDocumentError: `Something went wrong!`,
        requiredError: `It seems you've left some required fields blank. Please complete them.`,
        updateListingError: `The data which you have selected is not available. Please try again`,
        importSuccess: `Success! Your file has been imported successfully.`,
        addContent: `Please add content`,
        addLocationImage: `Please add location image`,
        addMetaTitle: `Please add Meta title`,
        addMetaDescription: `Please add  Meta description`,
        uploadPngImage: `You are trying to upload invalid image file. Please upload PNG format image file.`,
        csvFormat: `Please make sure the CSV format should have Make section`,
        csvFormatError: `Please make sure the CSV format should have Make, Model as format.`,
        uploadImage: `Please upload the image!`,
        isAdmin: `You are logged in as admin, you can't perform this action!`,
        checkUpcomingBooking: `You cannot delete this listing as it has upcoming bookings or enquiries.`,
        checkAge: `Sorry, you must be 18 years old`,
        checkDay: `Birth day field is missing`,
        checkMonth: `Birth month field is missing`,
        checkYear: `Birth year field is missing`,
        invalidDateOfBirth: `Invalid date of birth, please fill the valid data`,
        emailAlreadyExist: `Email already exist, please try another email address!`,
        loginUser: `You are not logged in, please login and try again!`,
        reloadPage: `Sorry, something went wrong! Reload this page and try again!`,
        uploadCarPhoto: `Your car photo is uploaded successfully!`,
        statusUpdate: `Status has been updated`,
        claimAmount: `Claim amount has been successfully refunded!`,
        emailSent: `Your email has been sent`,
        documentRemoved: `Document has been successfully removed!`,
        claimAmountUpdate: `Claim amount has been successfully updated!`,
        imageRemoved: `Image has been successfully removed`,
        phoneNumberVerify: `Phone number has been verified successfully.`,
        linkCopied: `Link copied successfully`,
        updateWhyHostPage: `Why become owner settings has been successfully updated!`,
        updateWhyHostPageFailed: `Failed to update why become owner settings`,
        alreadyReviewed: `You have already reviewed this reservations!`,
        datesNotAvailable: `Those dates are not available.`,
        inCompleteZipCode: `Your Zip code is incomplete.`,
        adminClaimModalError: `Something went wrong`,
        footerSettingsUpdate: `Footer settings changes has been successfully updated!`,
        footerSettingsUpdateFailed: `Failed to Updating the Footer settings`,
        blockSettingsFailed: `Failed to update static block settings`,
        blockSettingsUpdate: `Static block settings changes has been successfully updated!`,
        updateAdminReview: `Admin review details has been successfully updated!`,
        submitAdminReview: `Admin review details has been successfully submitted!`,
        updateUserReview: `User review details has been successfully updated!`,
        listIdNotAvailable: `List ID is not available!`,
        adminReviewFailed: `Your changes to admin review has failed!`,
        alreadyEmailExist: `Oops! this email address is already exist.`,
        adminDetailsFailed: `Your changes to admin access details is failed!`,
        settingsUpdate: `Settings changes has been successfully updated!`,
        settingsUpdateFailed: `Failed to Update settings`,
        searchSettingsUpdate: `Search settings changes has been successfully updated!`,
        searchSettingsUpdateFailed: `Failed to update Search settings`,
        paymentSettingsUpdate: `Payment settings changes has been successfully updated!`,
        paymentSettingsUpdateFailed: `Failed to update Payment settings`,
        updateListSettings: `The list settings has been successfully updated!`,
        checkActiveListing: `Unable to disable the list setting. At least, one list setting should be active.`,
        removeListSettings: `Unable to disable the list setting. The list setting is using on the cars. Please remove the list setting on the cars and try again.`,
        removeModel: `You can't able to do this action since the respective make is being used in the model. Please remove from the model page and proceed further`,
        listSettingsSuccess: `List Settings has been successfully created!`,
        listSettingsFailed: `Failed to Create List setting`,
        paymentTransferToReenter: `Payment has been successfully transferred to the renter`,
        paymentTransferFailed: `Payment to the Renter has failed, please try again with different currency`,
        checkStatus: error,
        duplicateFiles: `It seems your import file contains duplicate items. Please check the file and import the new items.`,
        limitExceed: `Limit Exceed! Please import the data up to 3000 records.`,
        invalidCurrency: "The currency you've chosen is not supported by PayPal for payment. Please change the currency and try making the payment once more.",
        checkPaymentState: 'For this booking, payment is already completed',
        checkMakeDetails: 'None of any make details found for the Model',
        adminUserLogged: 'It seems that you have already logged in as an admin user. Please log out from the site admin panel and try again.',
        isPublished: 'Oops! you could not recommend the unpublished list',
        securityDepositChange: "Security deposit has been updated! Please contact admin",
        networkFailed: 'You are offline. Please try again later'
    }
    return message[errorcode];
}
export default errorMessage;