exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".RegisterForm-displayGridDate-3jXcU {\n    display: grid;\n    grid-template-columns: 32% 36% 32%;\n}\n\n.RegisterForm-datePadding-3uW4m {\n    padding: 0px 8px;\n}\n\n.RegisterForm-checkBoxGrid-hzfz- {\n    display: grid;\n    grid-template-columns: 24px auto;\n}\n\n.RegisterForm-checkBoxText-3uSks {\n    padding-left: 7px;\n}\n\n@media screen and (max-width: 767px) {\n    .RegisterForm-displayGridDate-3jXcU {\n        grid-template-columns: 100%;\n    }\n\n    .RegisterForm-datePadding-3uW4m {\n        padding: 0px;\n    }\n}", ""]);

// exports
exports.locals = {
	"displayGridDate": "RegisterForm-displayGridDate-3jXcU",
	"datePadding": "RegisterForm-datePadding-3uW4m",
	"checkBoxGrid": "RegisterForm-checkBoxGrid-hzfz-",
	"checkBoxText": "RegisterForm-checkBoxText-3uSks"
};