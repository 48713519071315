exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, " .WishListIcon-iconContainer-365H5 {\n\tposition: absolute;\n    right: 26px;\n    top: 30px;\n\tz-index: 2;\n    cursor: pointer;\n}\n\n.WishListIcon-displayFlex-3BDVB {\n\tdisplay: -webkit-box;\n\tdisplay: -ms-flexbox;\n\tdisplay: flex;\n    -webkit-box-align: center;\n        -ms-flex-align: center;\n            align-items: center;\n}\n\n.WishListIcon-marginRight-1EoJ7 {\n    margin-right: 30px;\n}\n\n.WishListIcon-minWidth-2viqZ{\n    min-width: 91px;\n}", ""]);

// exports
exports.locals = {
	"iconContainer": "WishListIcon-iconContainer-365H5",
	"displayFlex": "WishListIcon-displayFlex-3BDVB",
	"marginRight": "WishListIcon-marginRight-1EoJ7",
	"minWidth": "WishListIcon-minWidth-2viqZ"
};