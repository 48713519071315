import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Stripe
import { Elements } from 'react-stripe-elements';

// Redux
import { connect } from 'react-redux';

import { formValueSelector } from 'redux-form';

// Components
import PayPal from './Paypal';
import PayoutDetailsForm from './PayoutDetailsForm';

class PayoutConfirm extends Component {
  static propTypes = {
    previousPage: PropTypes.any.isRequired,
    paymentType: PropTypes.string.isRequired,
  };

  render() {
    const { paymentType, previousPage } = this.props;
    if (paymentType === 2) {
      return (
        <Elements>
          <PayoutDetailsForm previousPage={previousPage} />
        </Elements>
      );
    } else {
      return <PayPal previousPage={previousPage} />;
    }
  }
}

const selector = formValueSelector('PayoutForm');

const mapState = (state) => ({
  paymentType: selector(state, 'paymentType'),
});

const mapDispatch = {};

export default connect(mapState, mapDispatch)(PayoutConfirm);
