exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".SaveButton-saveBtn-1bvyP {\n\tline-height: 43px;\n}\n\nspan {\n\ttext-decoration: none;\n}\n\nspan:hover, span:active, span:focus, span:visited {\n\ttext-decoration: none !important;\n}\n", ""]);

// exports
exports.locals = {
	"saveBtn": "SaveButton-saveBtn-1bvyP"
};