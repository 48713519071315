exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".WishLists-createIcon-1JAcO{\n    width: 16px;\n}\n.WishLists-flexColumn-3WRLF{\n    display: -webkit-box;\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-wrap: wrap;\n        flex-wrap: wrap;\n}", ""]);

// exports
exports.locals = {
	"createIcon": "WishLists-createIcon-1JAcO",
	"flexColumn": "WishLists-flexColumn-3WRLF"
};