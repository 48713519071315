exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".SocialLogin-displayFlex-1BRox {\n\tdisplay: -webkit-box;\n\tdisplay: -ms-flexbox;\n\tdisplay: flex;\n\t-webkit-box-align: center;\n\t    -ms-flex-align: center;\n\t        align-items: center;\n}\n\n.SocialLogin-marginRight-vLP8i {\n\tmargin-right: 23px;\n}\n\n.SocialLogin-center-3zBr-{\n\t-webkit-box-pack: center;\n\t    -ms-flex-pack: center;\n\t        justify-content: center;\n}", ""]);

// exports
exports.locals = {
	"displayFlex": "SocialLogin-displayFlex-1BRox",
	"marginRight": "SocialLogin-marginRight-vLP8i",
	"center": "SocialLogin-center-3zBr-"
};