import { gql } from 'react-apollo';

export const showVerificationDocuments = gql`
  query ShowDocumentList($userId: String, $listId: Int) {
    ShowDocumentList(userId: $userId, listId: $listId) {
      id
      userId
      listId
      fileName
      fileType
    }
  }
`;

export const uploadDocument = gql`
  mutation uploadDocument($fileName: String, $fileType: String, $listId: Int) {
    uploadDocument(fileName: $fileName, fileType: $fileType, listId: $listId) {
      status
      documentCount
      documents {
        id
        userId
        listId
        fileName
        fileType
      }
    }
  }
`;
export const removeDocument = gql`
  mutation RemoveDocumentList($id: Int, $listId: Int) {
    RemoveDocumentList(id: $id, listId: $listId) {
      status
      documentCount
      documents {
        id
        userId
        listId
        fileName
        fileType
      }
    }
  }
`;
