exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ListTitle-displayFlex-NvWN4 {\n    display: -webkit-box;\n    display: -ms-flexbox;\n    display: flex;\n    -webkit-box-pack: justify;\n        -ms-flex-pack: justify;\n            justify-content: space-between;\n    -ms-flex-wrap: wrap;\n        flex-wrap: wrap;\n}\n\n.ListTitle-padding-1uJ0Q {\n    padding: 42px 0px 27px;\n}\n\n.ListTitle-alignItemEnd-2fvkg {\n    -webkit-box-align: end;\n        -ms-flex-align: end;\n            align-items: end;\n}\n\n.ListTitle-alignItemCenter-3plKC {\n    -webkit-box-align: center;\n        -ms-flex-align: center;\n            align-items: center;\n}\n\n.ListTitle-lineHeight-2mtAH {\n    line-height: 20px;\n}\n\n.ListTitle-disGridTitle-3qdDs{\n    display: grid;\n    grid-template-columns:  65% auto;\n    -webkit-box-align: center;\n        -ms-flex-align: center;\n            align-items: center;\n}\n\n.ListTitle-textMinHeight-3mqe4{\n    min-height: 39px;\n}\n\n@media screen and (max-width: 768px) {\n    .ListTitle-disGridTitle-3qdDs{\n        grid-template-columns: 100%;\n    }\n    .ListTitle-justifyTab-3XP6q{\n        -webkit-box-pack: unset;\n            -ms-flex-pack: unset;\n                justify-content: unset;;\n    }\n}\n\n@media screen and (max-width: 767px) {\n    .ListTitle-mobilePadding-Qkltv {\n        padding-bottom: 6px;\n    }\n}", ""]);

// exports
exports.locals = {
	"displayFlex": "ListTitle-displayFlex-NvWN4",
	"padding": "ListTitle-padding-1uJ0Q",
	"alignItemEnd": "ListTitle-alignItemEnd-2fvkg",
	"alignItemCenter": "ListTitle-alignItemCenter-3plKC",
	"lineHeight": "ListTitle-lineHeight-2mtAH",
	"disGridTitle": "ListTitle-disGridTitle-3qdDs",
	"textMinHeight": "ListTitle-textMinHeight-3mqe4",
	"justifyTab": "ListTitle-justifyTab-3XP6q",
	"mobilePadding": "ListTitle-mobilePadding-Qkltv"
};