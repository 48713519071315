import {
  CREATE_VERIFICATION_DOCUMENTS_SUCCESS,
  REMOVE_VERIFICATION_DOCUMENTS_SUCCESS,
  GET_VERIFICATION_DOCUMENTS_SUCCESS,
} from '../constants';

export default function documentVerification(state = {}, action) {
  switch (action.type) {
    case CREATE_VERIFICATION_DOCUMENTS_SUCCESS:
      return {
        ...state,
        documents: action.documents,
        documentCount: action.documentCount,
      };

    case REMOVE_VERIFICATION_DOCUMENTS_SUCCESS:
      return {
        ...state,
        documents: action.documents,
        documentCount: action.documentCount,
      };

    case GET_VERIFICATION_DOCUMENTS_SUCCESS:
      return {
        ...state,
        documents: action.documents,
        documentCount: action.documentCount,
      };
    default:
      return state;
  }
}
