import messages from '../../../locale/messages';

const validatePayoutForm = (values) => {
  const errors = {};

  if (
    !values.address1 ||
    (values.address1 && values.address1.toString().trim() == '')
  ) {
    errors.address1 = messages.required;
  }

  if (!values.country) {
    errors.country = messages.required;
  }

  if (!values.city || (values.city && values.city.toString().trim() == '')) {
    errors.city = messages.required;
  }

  if (!values.state || (values.state && values.state.toString().trim() == '')) {
    errors.state = messages.required;
  }

  if (!values.zipcode) {
    errors.zipcode = messages.required;
  }

  if (
    !values.firstname ||
    (values.firstname && values.firstname.toString().trim() == '')
  ) {
    errors.firstname = messages.required;
  }

  if (
    values.businessType &&
    values.businessType === 'individual' &&
    (!values.lastname ||
      (values.lastname && values.lastname.toString().trim() == ''))
  ) {
    errors.lastname = messages.required;
  }

  if (!values.businessType) {
    errors.businessType = messages.required;
  }

  return errors;
};

export default validatePayoutForm;
